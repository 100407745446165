import { useEffect, useState } from 'react'
import useUser from '../../../hooks/useUser'
import { useMutation, useNotify, usePermissions } from 'react-admin'

const usePassword = () => {
  const notify = useNotify()
  const {
    id
  } = useUser()
  const { permissions } = usePermissions()
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [save, { loading, error, data }] = useMutation({
    type: 'update',
    resource: permissions,
    payload: { id, data: { password } }
  })

  const savePassword = async (event) => {
    event.preventDefault()
    if (
      !password ||
      !repeatPassword
    ) {
      notify('messages.settings.passwordsRequired', 'error')
      return false
    }
    if (password !== repeatPassword) {
      notify('messages.settings.passwordsMatch', 'error')
      return false
    }
    save()
  }

  useEffect(() => {
    if (error) {
      notify('messages.settings.passwordsPolicyMatch', 'error')
    }
  }, [error])

  useEffect(() => {
    if (data) {
      setPassword('')
      setRepeatPassword('')
      notify('messages.settings.passwordSave')
    }
  }, [data])

  return {
    savePassword,
    password,
    setPassword,
    repeatPassword,
    setRepeatPassword,
    loading
  }
}

export default usePassword
