import { FormTab, ImageField, ReferenceField, TextField } from 'react-admin'
import React from 'react'

export const MobileUserDetailsTab = (props) => {
  return (
    <FormTab {...props}>
      <ReferenceField
        source={props.mobileUserSource}
        label='Name'
        reference='mobileUsers'
        link={false}
      >
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Phone'
        reference='mobileUsers'
        link={false}
      >
        <TextField source='phone' />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Front'
        reference='mobileUsers'
        link={false}
      >
        <ImageField source='emiratesData.frontImage.publicUrl' />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Back'
        reference='mobileUsers'
        link={false}
      >
        <ImageField source='emiratesData.backImage.publicUrl' />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Document ID'
        reference='mobileUsers'
        link={false}
      >
        <TextField
          source='emiratesData.mrzData.document_number'
        />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Name'
        reference='mobileUsers'
        link={false}
      >
        <TextField
          source='emiratesData.mrzData.given_names_readable'
        />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Surname'
        reference='mobileUsers'
        link={false}
      >
        <TextField
          source='emiratesData.mrzData.surname'
        />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Nationality'
        reference='mobileUsers'
        link={false}
      >
        <TextField
          source='emiratesData.mrzData.nationality'
        />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Gender'
        reference='mobileUsers'
        link={false}
      >
        <TextField
          source='emiratesData.mrzData.sex'
        />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='ID'
        reference='mobileUsers'
        link={false}
      >
        <TextField
          source='emiratesData.mrzData.optionals'
        />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Expiration Date'
        reference='mobileUsers'
        link={false}
      >
        <TextField
          source='emiratesData.mrzData.expiration_date_readable'
        />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='DOB'
        reference='mobileUsers'
        link={false}
      >
        <TextField
          source='emiratesData.mrzData.dob_readable'
        />
      </ReferenceField>
      <ReferenceField
        source={props.mobileUserSource}
        label='Issuing Country'
        reference='mobileUsers'
        link={false}
      >
        <TextField
          source='emiratesData.mrzData.issuing_country'
        />
      </ReferenceField>
    </FormTab>
  )
}
