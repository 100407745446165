import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 20,
    '&:last-child': {
      margin: 0
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 1)
  },
  input: {
    minWidth: 400
  },
  inline: {
    display: 'inline-block'
  }
}))

export default useStyles
