import React, { useEffect } from 'react'
import {
  useLogin,
  useNotify,
  Notification,
  useTranslate,
  TextInput,
  required
} from 'react-admin'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Logo from '../../elements/Logo'
import Footer from '../../elements/Footer'
import { Form } from 'react-final-form'
import {
  useHistory,
  useLocation
} from 'react-router-dom'
import { debounce } from 'lodash'
import { authClientOptions } from '../../App'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    paddingBottom: 60,
    textAlign: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  },
  forgot: {
    margin: theme.spacing(1, 0, 2)
  }
}))

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const LoginPage = (props) => {
  const classes = useStyles()
  const login = useLogin()
  const notify = useNotify()
  const translate = useTranslate()
  const query = useQuery()
  const history = useHistory()
  const submit = (values) => {
    login({
      username: values.username,
      password: values.password
    })
      .catch((error) => {
        if (error.message.includes('OTP')) {
          history.push({
            pathname: '/verifyToken',
            state: {
              username: values.username,
              password: values.password
            }
          })
        } else {
          notify('messages.login.invalidEmailOrPassword')
        }
      })
  }
  const forgotPassword = (e) => {
    e.preventDefault()
    history.push('/forgotPassword')
  }

  const authenticateWithAzure = (e) => {
    e.preventDefault()
    window.location.replace(`${process.env.REACT_APP_API_URL}/oauth/azure`)
  }

  useEffect(() => {
    const accessToken = query.get('access_token')

    if (accessToken) {
      // eslint-disable-next-line no-undef
      localStorage.removeItem(authClientOptions.storageKey)
      // eslint-disable-next-line no-undef
      localStorage.setItem(authClientOptions.storageKey, accessToken)

      history.push('/dashboard')
    }
  }, [])

  useEffect(() => {
    const error = query.get('error')

    if (error) {
      notify(error, 'error', undefined, false, 5000)
    }
  }, [])

  return (
    <MuiThemeProvider theme={props.theme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <Logo big light />
            <Form
              className={classes.form}
              onSubmit={submit}
            >
              {props => (
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className={classes.form}
                >
                  <TextInput
                    variant='outlined'
                    margin='small'
                    fullWidth
                    id='username'
                    label='Email Address'
                    name='username'
                    type='email'
                    validate={required('messages.login.email.required')}
                    autoFocus
                  />
                  <TextInput
                    variant='outlined'
                    margin='small'
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    validate={required('messages.login.password.required')}
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    onClick={debounce(props.handleSubmit, 300)}
                    disabled={props.submitting || props.invalid}
                  >
                    Sign In
                  </Button>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='white'
                    startIcon={<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z"/></svg>}
                    className={classes.submit}
                    onClick={authenticateWithAzure}
                  >
                    Log in with Azure AD
                  </Button>
                  <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    className={classes.forgot}
                    onClick={forgotPassword}
                  >
                    Forgot Password?
                  </Button>
                  <Grid container>
                    <Grid
                      item
                      xs
                    >
                      {translate('loginPage.text')}
                      <a
                        target='_blank' rel='noopener noreferrer'
                        style={{ color: '#fff', margin: '0 3px' }}
                        href={translate('messages.footer.privacyPolicyLink')}
                      >
                        {translate('messages.footer.privacyPolicy')}
                      </a>
                      {translate('loginPage.and')}
                      <a
                        target='_blank' rel='noopener noreferrer'
                        style={{ color: '#fff', margin: '0 3px' }}
                        href={translate('messages.footer.termsAndConditionsLink')}
                      >
                        {translate('messages.footer.termsAndConditions')}
                      </a>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
            <Notification />
          </div>
        </div>
      </Container>
      <Footer />
    </MuiThemeProvider>
  )
}

export default LoginPage
