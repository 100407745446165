import React from 'react'
import { useTranslate } from 'react-admin'

import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import CircularProgress from '@material-ui/core/CircularProgress'

import useReportEvents from '../hooks/useReportEvents'
import CardWithIcon from './CardWithIcon'

const GuestsReported = () => {
  const translate = useTranslate()
  const { loaded, total } = useReportEvents()
  const subtitle = loaded ? total || 0 : <CircularProgress size={24} />

  return (
    <CardWithIcon
      type='error'
      icon={WarningRoundedIcon}
      title={translate('messages.dashboard.guestsReported')}
      subtitle={subtitle}
    />
  )
}

export default GuestsReported
