import {
  Create,
  Datagrid,
  List,
  ReferenceInput,
  ReferenceField,
  required,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  DeleteButton,
  useListContext,
  CreateButton, useQuery, TopToolbar
} from 'react-admin'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useUser from '../../hooks/useUser'
import UserManagementModal from '../userManagement/UserManagementModal'
import ImportButton from '../../elements/ImportButton'

const cardActionStyle = {
  zIndex: 2,
  position: 'absolute',
  right: 25
}

export const PropertyManagersPropertyCreate = (props) => {
  const { propertyId } = useSelector(state => state.property)
  return (
    <Create
      {...props}
      title='Create Manager'
    >
      <SimpleForm>
        <TextInput
          source='name'
          label='Name'
          validate={required()}
        />
        <TextInput
          source='email'
          label='E-mail'
          validate={required()}
          type='email'
        />
        <TextInput
          source='phone'
          label='Phone'
        />
        <ReferenceInput
          source='propertyId'
          label='Property'
          reference='properties'
          validate={required()}
          defaultValue={propertyId}
          readOnly
          style={{
            display: 'none'
          }}
        >
          <SelectInput readOnly optionText='name' />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

const EditActions = ({ basePath, data, resource, type }) => (
  <CardActions style={cardActionStyle}>
    {type === 'propertyAdmins' && <DeleteButton basePath={basePath} record={data} resource={resource} />}
  </CardActions>
)

export const PropertyManagersPropertyShow = (props) => {
  const { id } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })

  return (
    <Show
      {...props}
      title='Manager'
      actions={<EditActions type={userData?.type} />}
    >
      <SimpleShowLayout>
        <ReferenceField
          source='propertyManagerId'
          label='Name'
          reference='propertyManagers'
          link={false}
        >
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField
          source='propertyManagerId'
          label='Email'
          reference='propertyManagers'
          link={false}
        >
          <TextField source='email' />
        </ReferenceField>
        <ReferenceField
          source='propertyManagerId'
          label='Phone'
          reference='propertyManagers'
          link={false}
        >
          <TextField source='phone' />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  )
}

export const PropertyManagersPropertyListEmpty = (props) => {
  const { basePath } = useListContext()
  return (
    <Box textAlign='center' m={4}>
      <Typography variant='h4' paragraph>
        No managers yet
      </Typography>
      {props?.type === 'propertyAdmins' && (<>
        <Typography variant='body1'>
          Create new one
        </Typography>
        <CreateButton style={{ marginTop: 30 }} variant='contained' basePath={basePath} />
      </>)}
    </Box>
  )
}

export const PropertyManagersPropertyList = (props) => {
  const { propertyId } = useSelector(state => state.property)
  const { id } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })

  return (
    <List
      {...props}
      exporter={false}
      empty={<PropertyManagersPropertyListEmpty type={userData?.type} />}
      title='Managers'
      filter={{ propertyId: propertyId || undefined }}
      hasCreate={userData?.type === 'propertyAdmins'}
      bulkActionButtons={userData?.type === 'propertyAdmins'}
    >
      <Datagrid>
        <ReferenceField
          source='propertyManagerId'
          label='Name'
          reference='propertyManagers'
          link={false}
        >
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField
          source='propertyManagerId'
          label='Email'
          reference='propertyManagers'
          link={false}
        >
          <TextField source='email' />
        </ReferenceField>
        <ReferenceField
          source='propertyManagerId'
          label='Phone'
          reference='propertyManagers'
          link={false}
        >
          <TextField source='phone' />
        </ReferenceField>
        <ShowButton />
        {userData?.type === 'propertyAdmins' && <DeleteButton />}
      </Datagrid>
    </List>
  )
}
