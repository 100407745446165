import { useQueryWithStore } from 'react-admin'
import { useSelector } from 'react-redux'

import useDaysAgo from './useDaysAgo'

const useEnterEvents = () => {
  const { propertyId } = useSelector(state => state.property)
  const days = useSelector(state => state.globalFilters.days)
  const deviceId = useSelector(state => state.globalFilters.deviceId)
  const aMonthAgo = useDaysAgo(days)
  const { loaded, total } = useQueryWithStore({
    type: 'getList',
    resource: 'enterEvents',
    payload: {
      filter: {
        propertyId,
        'date[$gt]': aMonthAgo.toISOString(),
        deviceId
      },
      pagination: { page: 1, perPage: 1 }
    }
  })

  return {
    loaded,
    total,
    average: Math.ceil(total / days)
  }
}

export default useEnterEvents
