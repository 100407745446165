const initialState = {
  propertyId: '',
  extraFieldLabel: '',
  secondExtraFieldLabel: ''
}

// eslint-disable-next-line default-param-last
const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PROPERTY_SET':
      return {
        propertyId: action.property,
        extraFieldLabel: action.extraFieldLabel,
        secondExtraFieldLabel: action.secondExtraFieldLabel
      }
    default:
      return state
  }
}

export default propertyReducer
