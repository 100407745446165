import { useSelector } from 'react-redux'
import { useQuery } from 'react-admin'

const useSubscription = () => {
  const { propertyId } = useSelector(state => state.property)
  const { data } = useQuery({
    type: 'getList',
    resource: 'subscriptions',
    payload: { propertyId }
  })

  return {
    activeUsers: data?.[0]?.activeUsers ?? 0,
    maxUsers: data?.[0]?.maxUsers ?? 0
  }
}

export default useSubscription
