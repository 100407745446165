import React, { Fragment, useState } from 'react'
import {
  TextField,
  List,
  Datagrid,
  EditButton,
  ResourceContextProvider,
  ReferenceField,
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  DateField,
  SaveButton,
  Filter,
  useRefresh,
  useNotify,
  useUnselectAll,
  useUpdateMany,
  DateInput, SelectInput, useQuery, SelectField
} from 'react-admin'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import { addMonths } from 'date-fns'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MUITextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

export const Filters = (props) => {
  return (
    <Filter
      {...props}
      style={{
        marginTop: 0
      }}
    >
      <TextInput
        source='name.$regex'
        label='Name'
        alwaysOn
      />
      <TextInput
        source='accessCardId.$regex'
        label='Access Card ID'
        alwaysOn
      />
    </Filter>
  )
}

const AccessCardEditButton = ({ record }) => (
  <EditButton to={`/accessCards/${record.id}/edit`} record={record} />
)

export const AccessCardListEmpty = () => {
  return (
    <Box textAlign='center' m={4}>
      <Typography variant='h4' paragraph>
        No users yet.
      </Typography>
    </Box>
  )
}

const RemoveAccessCardButton = ({ selectedIds }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()
  const [open, setOpen] = useState(false)
  const [updateMany, { loading }] = useUpdateMany(
    'mobileUsers',
    selectedIds,
    { accessCardId: '' },
    {
      onSuccess: () => {
        refresh()
        notify('Access Card IDs cleared')
        unselectAll('mobileUsers')
      },
      onFailure: () => {
        notify('Error: access cards not updated', { type: 'warning' })
      }
    }
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Are you sure you want to revoke all access cards?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={loading}
            onClick={updateMany}
            type='submit'
          >{loading ? <CircularProgress color='inherit' size={16} /> : 'Confirm'}</Button>
        </DialogActions>
      </Dialog>
      <Button
        variant='text'
        color='default'
        style={{ color: 'white' }}
        startIcon={<ClearIcon />}
        onClick={handleClickOpen}
      >
        Clear
      </Button>
    </>
  )
}

const UpdateAccessCardExpiryDateButton = ({ selectedIds }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()
  const [open, setOpen] = useState(false)
  const [accessCardExpiryDate, setAccessCardExpiryDate] = useState(addMonths(new Date(), 1))
  const [updateMany, { loading }] = useUpdateMany(
    'mobileUsers',
    selectedIds,
    { accessCardExpiryDate },
    {
      onSuccess: () => {
        refresh()
        notify('Access Card Expiry Dates are updated')
        unselectAll('mobileUsers')
      },
      onFailure: () => {
        notify('Error: access cards not updated', { type: 'warning' })
      }
    }
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Update Access Card Expiry Date</DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          <MUITextField
            autoFocus
            InputLabelProps={{
              shrink: true
            }}
            required
            margin='dense'
            id='accessCardEpiryDate'
            name='accessCardEpiryDate'
            label='Expiry Date'
            type='date'
            fullWidth
            variant='standard'
            value={accessCardExpiryDate}
            onChange={(event) => setAccessCardExpiryDate(event.currentTarget?.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={loading}
            onClick={updateMany}
            type='submit'
          >{loading ? <CircularProgress color='inherit' size={16} /> : 'Update'}</Button>
        </DialogActions>
      </Dialog>
      <Button
        variant='text'
        color='default'
        style={{ color: 'white' }}
        onClick={handleClickOpen}
      >
        Update Expiry Date
      </Button>
    </>
  )
}

const AccessCardsBulkActionButtons = props => (
  <>
    <RemoveAccessCardButton {...props} />
    <UpdateAccessCardExpiryDateButton {...props} />
  </>
)

const BusinnesHoursProfilesField = props => {
  const { propertyId } = useSelector(state => state.property)
  const { data } = useQuery({
    type: 'getOne',
    resource: 'properties',
    payload: { id: propertyId }
  }, {
    enabled: Boolean(propertyId)
  })

  return (
    <SelectField
      choices={data?.businessHours?.profiles?.map(profile => ({
        id: String(profile.id),
        name: profile.name
      })) || []}
      {...props}
    />
  )
}

export const AccessCardsList = () => {
  const { propertyId } = useSelector(state => state.property)

  if (!propertyId) {
    return null
  }

  return (
    <ResourceContextProvider value='mobileUsers'>
      <List
        basePath='/mobileUsers'
        exporter={false}
        filter={{
          propertyId
        }}
        filters={propertyId && <Filters propertyId={propertyId} />}
        title='Access Cards'
        empty={<AccessCardListEmpty />}
        bulkActionButtons={<AccessCardsBulkActionButtons />}
      >
        <Datagrid>
          <ReferenceField
            source='id'
            label='Name'
            reference='mobileUsers'
            link='show'
          >
            <TextField source='name' />
          </ReferenceField>
          <TextField
            label='Designation'
            source='designation'
          />
          <TextField
            source='accessCardId'
            label='Access Card ID'
          />
          <DateField
            source='accessCardExpiryDate'
            label='Access Card Expiry Date'
          />
          <BusinnesHoursProfilesField
            source='businessHoursProfileId'
            label='Business Hours Profile'
          />
          <AccessCardEditButton />
        </Datagrid>
      </List>
    </ResourceContextProvider>
  )
}

const AccessCardEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      label='Save'
      redirect={false}
    />
  </Toolbar>
)

const BusinnesHoursProfilesInput = props => {
  const { propertyId } = useSelector(state => state.property)
  const { data } = useQuery({
    type: 'getOne',
    resource: 'properties',
    payload: { id: propertyId }
  }, {
    enabled: Boolean(propertyId)
  })

  return (
    <SelectInput
      choices={data?.businessHours?.profiles?.map(profile => ({
        id: String(profile.id),
        name: profile.name
      })) || []}
      resettable
      {...props}
    />
  )
}

export const AccessCardEdit = (props) => {
  return (
    <Edit {...props} title='Edit Access Card' resource='mobileUsers'>
      <SimpleForm toolbar={<AccessCardEditToolbar />} redirect={false}>
        <ReferenceField
          source='id'
          label='Name'
          reference='mobileUsers'
          basePath='mobileUsers'
          link='show'
        >
          <TextField source='name' />
        </ReferenceField>
        <TextInput
          source='accessCardId'
          label='Access Card ID'
        />
        <DateInput
          source='accessCardExpiryDate'
          label='Access Card Expiry Date'
          locales='en-UK'
        />
        <TextInput
          source='designation'
          label='Designation'
        />
        <BusinnesHoursProfilesInput
          source='businessHoursProfileId'
          label='Business Hours Profile'
        />
      </SimpleForm>
    </Edit>
  )
}
