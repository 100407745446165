import React from 'react'
import { useTranslate } from 'react-admin'

import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded'
import CircularProgress from '@material-ui/core/CircularProgress'

import useDeliveryEvents from '../hooks/useDeliveryEvents'
import CardWithIcon from './CardWithIcon'

const AverageDeliveries = () => {
  const translate = useTranslate()
  const { loaded, average } = useDeliveryEvents()
  const subtitle = loaded ? average || 0 : <CircularProgress size={24} />

  return (
    <CardWithIcon
      type='success'
      icon={LocalShippingRoundedIcon}
      title={translate('messages.dashboard.averageDeliveries')}
      subtitle={subtitle}
    />
  )
}

export default AverageDeliveries
