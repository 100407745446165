import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useTranslate } from 'react-admin'

export default function FlatsModal ({ open, setOpen, children }) {
  const translate = useTranslate()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button color='primary' component='span' size='small' variant='text' onClick={handleClickOpen}>
        <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: 20 }} />
        <span style={{ paddingLeft: '0.5em' }}>{translate('messages.flats.import')}</span>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='xl'
      >
        <DialogActions>
          <Button size='small' variant='outlined' href='/import-flats-sample.csv' color='primary'>
            <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: 20 }} />
            <span style={{ paddingLeft: '0.5em' }}>{translate('messages.flats.downloadSampleCsv')}</span>
          </Button>
          {children}
          <Button variant='filled' onClick={handleClose} color='primary'>
            {translate('messages.flats.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
