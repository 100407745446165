import { useMemo } from 'react'

import subDays from 'date-fns/subDays'
import startOfToday from 'date-fns/startOfToday'

const useDaysAgo = (days) => {
  const aDaysAgo = useMemo(() => {
    return subDays(startOfToday(), days)
  }, [days])

  return aDaysAgo
}

export default useDaysAgo
