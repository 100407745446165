import React from 'react'
import {
  Notification,
  useNotify,
  useRedirect,
  TextInput,
  required
} from 'react-admin'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Footer from '../../elements/Footer'
import { theme } from '../../theme'
import client from '../../client/feathersClient'
import { debounce } from 'lodash'
import { Form } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    paddingBottom: 60,
    textAlign: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  },
  backToLogin: {
    margin: theme.spacing(1, 0, 2)
  }
}))

const ForgotPassword = () => {
  const classes = useStyles()
  const redirect = useRedirect()
  const notify = useNotify()

  const submit = async (values) => {
    const userEmailAuthManagementService = client.service('userEmailAuthManagement')

    if (!values.email) {
      notify('messages.resetPassword.email.required', 'warning')
      return
    }

    try {
      await userEmailAuthManagementService.create({
        action: 'sendResetPwd',
        value: {
          email: values.email
        }
      })

      notify('Reset token was send')
      redirect('/resetPassword')
    } catch (error) {
      notify('messages.resetPassword.email.invalid', 'warning')
    }
  }

  const backToLogin = (e) => {
    e.preventDefault()
    redirect('/login')
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <Typography component='h1' variant='h5'>
              Forgot Password?
            </Typography>
            <Form
              className={classes.form}
              onSubmit={submit}
            >
              {props => (
                <from
                  onSubmit={(e) => e.preventDefault()}
                >
                  <TextInput
                    variant='outlined'
                    margin='normal'
                    validate={required('messages.resetPassword.email.required')}
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    type='email'
                    autoFocus
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    onClick={debounce(props.handleSubmit, 300)}
                    disabled={props.submitting || props.invalid}
                  >
                    Reset Password
                  </Button>
                  <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    className={classes.backToLogin}
                    onClick={backToLogin}
                  >
                    Back to Login
                  </Button>
                </from>
              )}
            </Form>
            <Notification />
          </div>
        </div>
      </Container>
      <Footer />
    </MuiThemeProvider>
  )
}

export default ForgotPassword
