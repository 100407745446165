import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Subscriptions from './components/Subscriptions'
import PropertyDetails from './components/PropertyDetails'
import ChangeDetails from './components/ChangeDetails'
import ChangePassword from './components/ChangePassword'
import { Notification, Title } from 'react-admin'
import AccessCardReportsReceivers from './components/AccessCardReportsReceivers'
import DailyEventsReportsReceivers from './components/DailyEventsReportsReceivers'

const Settings = () => {
  useEffect(() => {
    document.querySelector('#react-admin-title').textContent = 'Settings'
  })
  return (
    <>
      <Title title='Settings' />
      <PropertyDetails />
      <AccessCardReportsReceivers />
      <DailyEventsReportsReceivers />
      <ChangePassword />
      <ChangeDetails />
      <Subscriptions />
      <Notification />
    </>
  )
}

export default withRouter(Settings)
