import React, { cloneElement, useEffect } from 'react'
import {
  List,
  TextField,
  Filter,
  DateField,
  DateTimeInput,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  Pagination,
  Datagrid,
  TextInput,
  downloadCSV,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  ExportButton,
  SelectField, useQuery
} from 'react-admin'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TimeField from '../../elements/TimeField'
import { differenceInDays, format } from 'date-fns'
import jsonExport from 'jsonexport/dist'
import { allEventTypeChoices } from '../../constants'
import DurationField from '../../elements/DurationField'
import useReport from '../../hooks/useReport/index'
import useUser from '../../hooks/useUser'
import Button from '@material-ui/core/Button'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

const MAX_QUERY_ARRAY_LENGTH = 200

export const Filters = (props) => {
  const toRegex = (field) => {
    return searchText => {
      return searchText && {
        [`${field}.$regex`]: searchText,
        [`${field}.$options`]: 'i'
      }
    }
  }

  return (
    <Filter
      {...props} style={{
        marginTop: 0
      }}
    >
      <DateTimeInput
        source='dateStart'
        label='From'
        allowEmpty
      />
      <DateTimeInput
        source='dateEnd'
        label='To'
        allowEmpty
      />
      <ReferenceInput
        label='Host'
        source='hostId'
        reference='mobileUsers'
        allowEmpty
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
      <ReferenceInput
        label='Device'
        source='deviceId'
        reference='devices'
        allowEmpty
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
        filter={{ propertyId: props.propertyId || undefined }}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
      <TextInput
        source='accessCardId'
        label='Access Card ID'
      />
      <TextInput
        label='Designation'
        source='designation'
      />
      <TextInput
        source='message'
        label='Message'
      />
    </Filter>
  )
}

export const LogListEmpty = () => {
  return (
    <Box textAlign='center' m={4}>
      <Typography variant='h4' paragraph>
        No access card logs available
      </Typography>
    </Box>
  )
}

export const LogsPaginationActions = () => {
  const { page, perPage, total, setPage } = useListContext()
  const nbPages = Math.ceil(total / perPage) || 1
  return (
    nbPages > 1 && <Box display='flex' alignItems='center' ml={3}>
      <Button
        color='primary'
        disabled={page <= 1}
        {...page <= 1 && { style: { color: '#c5c5c5' } }}
        key='prev'
        onClick={() => setPage(page - 1)}
      >
        <ChevronLeft />
        Prev
      </Button>
      <Button
        color='primary'
        disabled={page === nbPages}
        {...page === nbPages && { style: { color: '#c5c5c5' } }}
        key='next'
        onClick={() => setPage(page + 1)}
      >
        Next
        <ChevronRight />
      </Button>
    </Box>
  )
}

const LogsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} ActionsComponent={LogsPaginationActions} />

const exporter = async (records, fetchRelatedRecords, propertyId) => {
  let hosts = {}
  let devices = {}
  const numbersOfRequests = Math.ceil(records.length / MAX_QUERY_ARRAY_LENGTH)
  const promises = []
  for (let i = 0; i < numbersOfRequests; i++) {
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(records.slice(i * MAX_QUERY_ARRAY_LENGTH, (i + 1) * MAX_QUERY_ARRAY_LENGTH), 'hostId', 'mobileUsers')
        .then((data) => {
          hosts = { ...hosts, ...data }
          resolve()
        })
    }))
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(records.slice(i * MAX_QUERY_ARRAY_LENGTH, (i + 1) * MAX_QUERY_ARRAY_LENGTH), 'deviceId', 'devices')
        .then((data) => {
          devices = { ...devices, ...data }
          resolve()
        })
    }))
  }

  Promise.all(promises).then(() => {
    const data = records.filter(record => record.propertyId === propertyId).map(record => ({
      date: format(new Date(record.date), 'dd/MM/yyyy'),
      time: format(new Date(record.date), 'HH:mm'),
      host: hosts[record.hostId]?.name,
      designation: hosts[record.hostId]?.designation,
      device: devices[record.deviceId]?.name,
      type: allEventTypeChoices.find(type => record.type === type.id).name,
      message: record.message
    }))

    const headers = ['date', 'time', 'host', 'designation', 'device', 'type', 'message']
    const rename = ['Date', 'Time', 'Host', 'Designation', 'Device', 'Type', 'Message']

    jsonExport(data, {
      headers,
      rename
    }, (err, csv) => {
      if (err) {
        console.error(err)
      }
      downloadCSV(csv, 'access_cards_logs_' + new Date().toISOString())
    })
  })
}

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
    setFilters
  } = useListContext()
  const { propertyId } = useSelector(state => state.property)
  const { id } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  }, {
    enabled: Boolean(id)
  })
  const { generateNewReport } = useReport()

  useEffect(() => {
    setFilters({ ...filterValues, propertyId }, displayedFilters)
  }, [propertyId])

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
      {
        userData?.type === 'propertyAdmins' && (
          <ExportButton
            disabled={
              total === 0 ||
              !filterValues?.dateStart ||
              !filterValues?.dateEnd ||
              (
                filterValues?.dateStart &&
                filterValues?.dateEnd &&
                differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) > 92
              ) ||
              (
                filterValues?.dateStart &&
                filterValues?.dateEnd &&
                differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) < 0
              )
            }
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={150000}
            label='Export to CSV'
          />
        )
      }
      {
        userData?.type === 'propertyAdmins' && (
          <ExportButton
            size='small'
            disabled={
             total === 0 ||
             !filterValues?.dateStart ||
             !filterValues?.dateEnd ||
             (
               filterValues?.dateStart &&
               filterValues?.dateEnd &&
               differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) > 92
             ) ||
             (
               filterValues?.dateStart &&
               filterValues?.dateEnd &&
               differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) < 0
             )
            }
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={150000}
            label='Generate report'
            exporter={(records, fetchRelatedRecords) => generateNewReport({
              records,
              fetchRelatedRecords,
              from: new Date(filterValues?.dateStart),
              to: new Date(filterValues?.dateEnd),
              filters: filterValues
            })}
          />
        )
      }
    </TopToolbar>
  )
}

export const AccessCardEventsView = (props) => {
  const { propertyId } = useSelector(state => state.property)

  return (
    <List
      {...props}
      pagination={<LogsPagination />}
      empty={<LogListEmpty />}
      title='Logs'
      filters={propertyId && <Filters propertyId={propertyId} />}
      bulkActionButtons={false}
      filter={{
        propertyId: propertyId || undefined,
        accessCardLogs: true,
        $select: ['date', 'type', 'hostId', 'deviceId', 'propertyId', 'flatId', 'guestName', 'guestMobileNumber', 'delivererName', 'delivererMobileNumber', 'message', 'duration', 'extraField', 'secondExtraField']
      }}
      resource='logs'
      sort={{ field: 'date', order: 'DESC' }}
      exporter={(records, fetchRelatedRecords) => exporter(records, fetchRelatedRecords, propertyId)}
      actions={<ListActions />}
    >
      <Datagrid>
        <DateField
          source='date'
          label='Date'
        />
        <TimeField
          source='date'
          label='Time'
        />
        <ReferenceField
          source='hostId'
          reference='mobileUsers'
          basePath='mobileUsers'
          link='show'
          label='Host'
        >
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField
          source='hostId'
          reference='mobileUsers'
          basePath='mobileUsers'
          label='Designation'
        >
          <TextField source='designation' />
        </ReferenceField>
        <ReferenceField
          source='deviceId'
          reference='devices'
          basePath='devices'
          link='show'
          label='Device'
        >
          <TextField source='name' />
        </ReferenceField>
        <SelectField
          source='type'
          choices={allEventTypeChoices}
        />
        <DurationField
          sortable={false}
          source='duration'
          label='Duration'
        />
        <TextField
          source='message'
          label='Message'
        />
      </Datagrid>
    </List>
  )
}
