import React from 'react'
import {
  TextInput,
  List,
  Datagrid,
  TextField,
  Filter
} from 'react-admin'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Name'
      source='name.$regex'
      alwaysOn
    />
  </Filter>
)

export const PropertyList = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      filters={<Filters />}
    >
      <Datagrid>
        <TextField
          source='name'
          label='Name'
        />
      </Datagrid>
    </List>
  )
}
