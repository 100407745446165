import React, { useCallback, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import CircularProgress from '@material-ui/core/CircularProgress'
import { parse as convertFromCSV } from 'papaparse'
import { useNotify, useRefresh } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import client from '../client/feathersClient'

const ImportButton = (props) => {
  const { resource, filled, additionalRequestData, prepareData } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const fileInput = useRef()

  const [fileName, setFileName] = useState('')
  const [isCreatingMobileUsersFlats, setIsCreatingMobileUsersFlats] = useState(false)
  const [showFileErrorDialog, setShowFileErrorDialog] = useState(false)
  const [fileErrors, setFileErrors] = useState()
  const [showImportErrorDialog, setShowImportErrorDialog] = useState(false)
  const [importErrors, setImportErrors] = useState()

  const handleChange = (e) => {
    const file = e.target.files && e.target.files[0]

    if (file) {
      setFileName(file.name)
      setImportErrors([])
      setFileErrors([])
      setIsCreatingMobileUsersFlats(true)

      convertFromCSV(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (result) => {
          try {
            fileInput.current.value = null

            const { data: parsedData, errors } = result

            if (errors && errors.length > 0) {
              setFileErrors(errors)
              setShowFileErrorDialog(true)
              return false
            }

            let data = parsedData

            if (prepareData) {
              for (const row in data) {
                data[row] = await props.prepareData(data[row])
              }

              data = data.filter(item => !!item) // filter nulls

              if (resource === 'mobileUsersFlats') {
                data = data.map((item, index) => {
                  item.index = index
                  return item
                })
                  .filter(item => item.phone && item.name?.length > 0 && item.flatId)
              }
            }

            if (!data || data.length === 0) {
              if (resource === 'mobileUsersFlats') {
                notify('There is no data to import. Bad file or flat number is not existing', 'warning')
              } else {
                notify('There is no data to import', 'warning')
              }
              return false
            }

            let total = 0

            const importErrorsTemp = []
            const importPromises = data.map(async (row) => {
              try {
                await client.service(resource).create({
                  ...row,
                  ...additionalRequestData
                })
                total++
              } catch (error) {
                importErrorsTemp.push({
                  index: row.index,
                  message: error.message
                })
              }
            })

            await Promise.all(importPromises)

            setIsCreatingMobileUsersFlats(false)

            if (importErrorsTemp.length > 0) {
              setImportErrors(importErrorsTemp)
              setShowImportErrorDialog(true)
            } else {
              refresh()
              if (resource === 'mobileUsersFlats') {
                notify('messages.users.imported', 'info', { total, totalLines: parsedData.length, skipped: parsedData.length - total })
              } else {
                notify('messages.flats.imported', 'info', { total, totalLines: parsedData.length, skipped: parsedData.length - total })
              }
              props.setOpenModal && props.setOpenModal(false)
            }
          } catch (e) {
            notify('Unexpected error happened', 'warning')
            props.setOpenModal && props.setOpenModal(false)
            console.error(e)
            setIsCreatingMobileUsersFlats(false)
          }
        }
      })
    }
  }

  const fileErrorDialogOnClose = useCallback(() => {
    refresh()
    setShowFileErrorDialog(false)
  }, [refresh])

  const importDialogOnClose = useCallback(() => {
    refresh()
    setShowImportErrorDialog(false)
  }, [refresh])

  return (
    <>
      <Dialog
        fullWidth
        open={showFileErrorDialog}
        onClose={fileErrorDialogOnClose}
        aria-label={`${fileName} imported with errors`}
      >
        <DialogTitle style={{ color: 'rgb(232, 59, 70)' }}>"{fileName}" imported with errors</DialogTitle>
        <DialogContent style={{ marginBottom: 20 }}>
          {
            fileErrors?.map(error => (
              <Box key={'csv' + error.row} mb={1}>Line {error.row - 1}: {error.message}</Box>
            ))
          }
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        open={showImportErrorDialog}
        onClose={importDialogOnClose}
        aria-label='Import CSV finished with errors'
      >
        <DialogTitle style={{ color: 'rgb(232, 59, 70)' }}>Import CSV finished with errors</DialogTitle>
        <DialogContent style={{ marginBottom: 20 }}>
          {
            importErrors?.sort((a, b) => a.index - b.index).map(error => (
              <Box key={'import' + error.row} mb={1}>Line {error.index + 1}: {error.message}</Box>
            ))
          }
        </DialogContent>
      </Dialog>
      <input
        type='file'
        id='importButton'
        style={{ display: 'none' }}
        ref={fileInput}
        accept='.csv'
        onChange={handleChange}
      />
      <label
        htmlFor='importButton'
        style={{
          display: 'inline-flex',
          alignItems: 'center'
        }}
      >
        <Button
          color='primary'
          component='span'
          size='small'
          variant={filled ? 'outlined' : 'text'}
          disabled={isCreatingMobileUsersFlats}
        >
          {
            isCreatingMobileUsersFlats
              ? <CircularProgress size={23} />
              : <><GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: 20 }} /><span style={{ paddingLeft: '0.5em' }}>Import</span></>
          }
        </Button>
      </label>
    </>
  )
}

export default ImportButton
