import React from 'react'
import { useTranslate } from 'react-admin'

import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded'
import CircularProgress from '@material-ui/core/CircularProgress'

import useLeaveEvents from '../hooks/useLeaveEvents'
import CardWithIcon from './CardWithIcon'

const AutoSignOut = () => {
  const translate = useTranslate()
  const { loaded, percent } = useLeaveEvents()
  const subtitle = loaded ? percent || 0 : <CircularProgress size={24} />

  return (
    <CardWithIcon
      type='error'
      icon={ExitToAppRoundedIcon}
      title={translate('messages.dashboard.autoSignOut')}
      subtitle={subtitle}
    />
  )
}

export default AutoSignOut
