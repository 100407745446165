import React from 'react'
import {
  List,
  Datagrid,
  TextField
} from 'react-admin'
import { useSelector } from 'react-redux'

export const DeviceList = (props) => {
  const { propertyId } = useSelector(state => state.property)
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      filter={{ propertyId: propertyId || undefined }}
    >
      <Datagrid>
        <TextField
          source='name'
          label='Name'
        />
        <TextField
          source='licencesCode'
          label='Licence Code'
        />
      </Datagrid>
    </List>
  )
}
