import englishMessages from 'ra-language-english'

const messages = {
  footer: {
    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicyLink: 'https://www.buzzin.ae/privacy-policy/',
    termsAndConditionsLink: 'https://www.buzzin.ae/terms-of-service/',
    site: 'www.buzzin.ae',
    email: 'support@buzzin.ae'
  },
  dashboard: {
    totalVisitors: 'Total visitors',
    averageVisitors: 'Average visitors/day',
    guestsReported: 'Guests reported',
    totalDeliveries: 'Total deliveries',
    averageDeliveries: 'Average deliveries/day',
    autoSignOut: 'Auto Sign out',
    activeUsers: 'Active users',
    topFiveUnits: 'Top 5 units receiving visitors',
    topFiveDeliveryCompanies: 'Top 5 delivery companies',
    visitorsTraffic: 'Visitors\' Traffic Chart',
    lastDaysFilterOption: 'Last %{days} days',
    lastDayFilterOption: 'Last %{days} day',
    visitorsInBuilding: 'Visitors in the community',
    showDetails: 'Show details',
    close: 'Close',
    information: 'Information',
    guestPhoneNumber: 'Guest phone number',
    entryType: 'Entry type',
    date: 'Date',
    hostDetails: 'Host details'
  },
  userManagement: {
    import: 'Import',
    downloadSampleCsv: 'Download Sample CSV',
    close: 'Close'
  },
  settings: {
    subscription: 'To change subscription contact your reseller (sales@buzzin.ae)',
    passwordsRequired: 'Password and Confirm Password are required',
    passwordsMatch: 'Password doesn\'t match',
    passwordsPolicyMatch: 'Password is not strong enough',
    passwordSave: 'Password is changed',
    nameRequired: 'Name is required',
    emailRequired: 'Email is required',
    userDetailsSave: 'User details are changed',
    companyDetailsSave: 'Company details are changed'
  },
  resetPassword: {
    email: {
      required: 'Email address is required',
      invalid: 'Email address is invalid'
    },
    token: {
      required: 'Reset token is required'
    },
    newPassword: {
      required: 'New password is required'
    },
    invalid: 'Form is not valid',
    passwordResetSuccessfully: 'Your password has been reset successfully!'
  },
  login: {
    email: {
      required: 'Email address is required'
    },
    password: {
      required: 'Password is required'
    },
    invalidEmailOrPassword: 'Invalid email address or password'
  },
  verifyToken: {
    invalidOrExpiredVerifyCode: 'Invalid or expired code',
    otp: {
      required: 'Code is required'
    }
  },
  validation: {
    mobilePhone: 'Not valid phone number'
  },
  users: {
    imported: 'Imported %{total} out of %{totalLines} users from file, skipped %{skipped}'
  },
  flats: {
    imported: 'Imported %{total} out of %{totalLines} units from file, skipped %{skipped}',
    import: 'Import',
    downloadSampleCsv: 'Download Sample CSV',
    close: 'Close'
  }
}

const loginPage = {
  text: 'By signing in you are accepting the',
  and: 'and'
}

export default {
  ...englishMessages,
  messages,
  loginPage
}
