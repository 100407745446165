import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import { useDataProvider, useRefresh } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch, useSelector } from 'react-redux'

const BootstrapInput = withStyles(() => ({
  input: {
    borderBottom: '2px solid #E19709',
    color: '#fff',
    mixBlendMode: 'none',
    '&:focus': {
      borderColor: '#ffffff'
    }
  }
}))(InputBase)

export const PropertiesList = () => {
  const dataProvider = useDataProvider()
  const [propertiesList, setPropertiesList] = useState([])
  const [loading, setLoading] = useState(true)
  const { propertyId } = useSelector(state => state.property)
  const dispatch = useDispatch()
  const refresh = useRefresh()
  useEffect(() => {
    let isMounted = true
    dataProvider.getList('properties', {})
      .then(({ data }) => {
        if (!isMounted) {
          return
        }
        setPropertiesList(data)
        if (!propertyId) { dispatch({ type: 'PROPERTY_SET', property: data?.[0]?._id, extraFieldLabel: data?.[0]?.extraFieldLabel, secondExtraFieldLabel: data?.[0]?.secondExtraFieldLabel }) }
        refresh()
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
    return () => {
      isMounted = false
    }
  }, [dispatch, refresh, dataProvider, propertyId])

  if (loading) return <CircularProgress color='inherit' thickness={2.8} size={20} />

  return propertiesList
    ? (
      <Select
        style={{
          width: 200
        }}
        value={propertyId}
        input={<BootstrapInput />}
        onChange={event => {
          dispatch({
            type: 'PROPERTY_SET',
            property: event.target.value,
            extraFieldLabel: propertiesList.find(property => property._id === event.target.value).extraFieldLabel,
            secondExtraFieldLabel: propertiesList.find(property => property._id === event.target.value).secondExtraFieldLabel
          })
          refresh()
        }}
        inputProps={{
          name: 'property',
          id: 'property'
        }}
      >
        {
          propertiesList?.map((property) => (
            <MenuItem
              onMouseEnter={(e) => (e.target.style.color = '#E19709')}
              onMouseLeave={(e) => (e.target.style.color = 'inherit')}
              key={property._id}
              value={property._id}
            >
              {property.name}
            </MenuItem>
          ))
        }
      </Select>
      )
    : null
}
