import React from 'react'
import { useTranslate } from 'react-admin'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import HouseRoundedIcon from '@material-ui/icons/HouseRounded'

import useTopUnits from '../hooks/useTopUnits'
import CardWithIcon from './CardWithIcon'

const TopUnits = () => {
  const translate = useTranslate()
  const { loaded, data } = useTopUnits()
  const hasData = data && data.length > 0

  return (
    <CardWithIcon
      type='brown'
      icon={HouseRoundedIcon}
      title={translate('messages.dashboard.topFiveUnits')}
    >
      <List>
        {loaded
          ? (
              hasData
                ? (
                    data.map((record) => (
                      <ListItem key={record.id}>
                        <ListItemText primary={record.flatNumber} />
                        <ListItemText
                          primary={`${record.visitorsCount} ${
                    record.visitorsCount > 1 ? 'visitors' : 'visitor'
                  }`}
                          style={{ textAlign: 'right' }}
                        />
                      </ListItem>
                    ))
                  )
                : (
                  <ListItem>
                    <ListItemText primary='N/D' />
                  </ListItem>
                  )
            )
          : (
            <ListItem>
              <CircularProgress size={24} />
            </ListItem>
            )}
      </List>
    </CardWithIcon>
  )
}

export default TopUnits
