import React from 'react'
import { useTranslate } from 'react-admin'

import PersonIcon from '@material-ui/icons/Person'
import CircularProgress from '@material-ui/core/CircularProgress'

import CardWithIcon from './CardWithIcon'
import useVisitorsInBuilding from '../../hooks/useVisitorsInBuilding'
import VisitorsInBuildingModal from './VisitorsInBuildingModal'

const VisitorsInBuilding = () => {
  const translate = useTranslate()
  const { loaded, total, eventsData } = useVisitorsInBuilding()
  const subtitle = loaded ? total || 0 : <CircularProgress size={24} />

  return (
    <>
      <CardWithIcon
        type='success'
        icon={PersonIcon}
        title={translate('messages.dashboard.visitorsInBuilding')}
        subtitle={subtitle}
      >
        {eventsData && <VisitorsInBuildingModal eventsData={eventsData} />}
      </CardWithIcon>
    </>
  )
}

export default VisitorsInBuilding
