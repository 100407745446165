export const ESecurityLevel = {
  HIGH: 'High',
  MID: 'Mid'
}

export const EVENT_TYPE = {
  ENTER_EVENTS: 'enterEvents',
  LEAVE_EVENTS: 'leaveEvents',
  REPORT_EVENTS: 'reportEvents',
  RESOLVE_EVENTS: 'resolveEvents',
  DELIVERY_EVENT: 'deliveryEvents',
  ACCESS_CARD_EVENT: 'accessCardEvents',
  ACCESS_CARD_LEAVE_EVENT: 'accessCardLeaveEvents'
}

export const eventTypeChoices = [
  { id: EVENT_TYPE.ENTER_EVENTS, name: 'Enter' },
  { id: EVENT_TYPE.LEAVE_EVENTS, name: 'Leave' },
  { id: EVENT_TYPE.REPORT_EVENTS, name: 'Report' },
  { id: EVENT_TYPE.DELIVERY_EVENT, name: 'Delivery' },
  { id: EVENT_TYPE.RESOLVE_EVENTS, name: 'Resolve' }
]

export const allEventTypeChoices = [
  ...eventTypeChoices,
  { id: EVENT_TYPE.ACCESS_CARD_EVENT, name: 'Enter' },
  { id: EVENT_TYPE.ACCESS_CARD_LEAVE_EVENT, name: 'Leave' }
]
