const orangeCell = {
  alignment: {
    vertical: 'center',
    horizontal: 'center'
  },
  font: {
    bold: true,
    color: {
      rgb: 'ffffff'
    }
  },
  fill: {
    fgColor: {
      rgb: 'ed7d31'
    }
  }
}

const redCell = {
  alignment: {
    vertical: 'center',
    horizontal: 'center'
  },
  font: {
    bold: true,
    color: {
      rgb: 'ffffff'
    }
  },
  fill: {
    fgColor: {
      rgb: 'ff0000'
    }
  }
}

const greyCell = {
  alignment: {
    vertical: 'center',
    horizontal: 'center'
  },
  font: {
    bold: true,
    color: {
      rgb: '919191'
    }
  },
  fill: {
    fgColor: {
      rgb: 'D3D3D3'
    }
  }
}

const yellowCell = {
  fill: {
    fgColor: {
      rgb: 'ffff00'
    }
  }
}

const createWorksheetData = (name, data) => {
  return [
    [{
      v: name,
      s: {
        alignment: {
          vertical: 'center',
          horizontal: 'center'
        },
        font: {
          bold: true,
          color: {
            rgb: '4472c4'
          }
        },
        border: {
          top: { style: 'medium' },
          left: { style: 'medium' }
        }
      }
    }, '', '', '', '', '', '', '', {
      v: 'Notes',
      s: {
        alignment: {
          vertical: 'center',
          horizontal: 'center'
        },
        font: {
          bold: true
        },
        border: {
          top: { style: 'medium' },
          left: { style: 'medium' },
          bottom: { style: 'thin' }
        }
      }
    }, '', '', { v: '', s: { border: { right: { style: 'medium' } } } }],
    ['', '', '', '', '', '', '', '',
      {
        v: data.filter(row => row.notesDuration === 'D')?.length || '0',
        s: {
          border: {
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'thin' }
          }
        }
      },
      {
        v: data.filter(row => row.notesLateIn === 'L')?.length || '0',
        s: {
          border: {
            bottom: { style: 'medium' },
            right: { style: 'thin' }
          }
        }
      },
      {
        v: data.filter(row => row.notesEarlyOut === 'E')?.length || '0',
        s: {
          border: {
            bottom: { style: 'medium' },
            right: { style: 'thin' }
          }
        }
      },
      {
        v: data.filter(row => row.notesAbsent === 'A')?.length || '0',
        s: {
          border: {
            bottom: { style: 'medium' },
            right: { style: 'medium' }
          }
        }
      }
    ],
    [
      {
        v: 'Date',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Name',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'ID # (Unit Number)',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Designation',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Tag Number',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Time-In',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Time-Out',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Duration',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'medium' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Duration',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Late In',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Early Out',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Absent',
        s: {
          font: { bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium' },
            left: { style: 'thin' },
            right: { style: 'medium' },
            bottom: { style: 'medium' }
          }
        }
      }
    ],
    ...data.map((row) => [
      {
        v: row.date,
        s: {
          border: {
            left: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.name,
        s: {
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.unitNumber,
        s: {
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.designation || '',
        s: {
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.accessCard || '',
        s: {
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.timeIn,
        s: {
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.autoSignOut ? 'NO RECORD' : row.timeOut,
        s: {
          ...row.autoSignOut && yellowCell,
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.duration,
        s: {
          ...row.multipleDurations && yellowCell,
          border: {
            right: { style: 'medium' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.notesDuration,
        s: {
          ...row.notesDuration === 'D' && orangeCell,
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.notesLateIn,
        s: {
          ...row.notesLateIn === 'L' && orangeCell,
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.notesEarlyOut,
        s: {
          ...row.notesEarlyOut === 'E' && orangeCell,
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.notesAbsent,
        s: {
          ...row.notesAbsent === 'A' && redCell,
          ...row.notesAbsent === 'W' && greyCell,
          border: {
            right: { style: 'medium' },
            bottom: { style: 'thin' }
          }
        }
      }
    ])
  ]
}

export default createWorksheetData
