import React, { useState } from 'react'
import {
  SimpleForm,
  Create,
  TextInput,
  Edit,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  CreateButton,
  useListContext,
  required,
  Toolbar,
  SaveButton, BooleanInput, BooleanField, TopToolbar, useQuery
} from 'react-admin'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import BulkUpdateUnits from './BulkUpdateUnits'
import ImportButton from '../../elements/ImportButton'
import FlatsModal from './FlatsModal'
import useUser from '../../hooks/useUser'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Unit number'
      source='flatNumber.$regex'
      alwaysOn
    />
  </Filter>
)

export const FlatCreate = (props) => {
  const { propertyId } = useSelector(state => state.property)
  return (
    <Create
      {...props}
      title='Create Unit'
    >
      <SimpleForm>
        <TextInput
          source='flatNumber'
          label='Unit number'
          type='text'
          validate={required()}
        />
        <ReferenceInput
          source='propertyId'
          label='Property'
          reference='properties'
          validate={required()}
          defaultValue={propertyId}
          style={{
            display: 'none'
          }}
        >
          <SelectInput optionText='name' />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const FlatEdit = (props) => {
  const { propertyId } = useSelector(state => state.property)
  return (
    <Edit
      {...props}
      title='Edit Unit'
    >
      <TabbedForm
        redirect={false}
        toolbar={<EditToolbar />}
      >
        <FormTab label='summary'>
          <TextInput
            source='flatNumber'
            label='Unit number'
            type='text'
            validate={required()}
          />
          <BooleanInput
            source='isPreset'
            label='Preset'
          />
          <ReferenceInput
            source='propertyId'
            label='Property'
            reference='properties'
            validate={required()}
            defaultValue={propertyId}
            style={{
              display: 'none'
            }}
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const FlatListEmpty = () => {
  const { basePath } = useListContext()
  return (
    <Box textAlign='center' m={4}>
      <Typography variant='h4' paragraph>
        No units available
      </Typography>
      <Typography variant='body1'>
        Create new one
      </Typography>
      <CreateButton style={{ marginTop: 30 }} variant='contained' basePath={basePath} />
    </Box>
  )
}

const prepareData = (propertyId) => {
  const _propertyId = propertyId

  return async (data) => {
    return {
      ...data,
      propertyId: _propertyId
    }
  }
}

const TopToolbarActions = props => {
  const { propertyId } = useSelector(state => state.property)

  const [openModal, setOpenModal] = useState(false)

  const { className, basePath, resource } = props
  return (
    <TopToolbar className={className}>
      {props.type === 'propertyAdmins' && (<>
        <CreateButton basePath={basePath} />
        <FlatsModal open={openModal} setOpen={setOpenModal}>
          <ImportButton
            filled
            additionalRequestData={{ propertyId }}
            resource={resource}
            prepareData={prepareData(propertyId)}
            setOpenModal={setOpenModal}
          />
        </FlatsModal>
      </>)}
    </TopToolbar>
  )
}

export const FlatList = (props) => {
  const { propertyId } = useSelector(state => state.property)
  const { id } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })

  return (
    <List
      {...props}
      exporter={false}
      empty={<FlatListEmpty />}
      title='Units'
      filters={<Filters />}
      filter={{
        propertyId: propertyId || undefined,
        isArchived: {
          $ne: true
        }
      }}
      bulkActionButtons={userData?.type === 'propertyAdmins' ? <BulkUpdateUnits {...props} label='Archive units' /> : false}
      actions={<TopToolbarActions type={userData?.type} />}
    >
      <Datagrid>
        <TextField
          source='flatNumber'
          label='Unit number'
        />
        <BooleanField source='isPreset' label='Is Preset' />
        {userData?.type === 'propertyAdmins' && <EditButton />}
      </Datagrid>
    </List>
  )
}
