import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslate } from 'react-admin'
import VisitorsInBuildingTable from './VisitorsInBuildingTable'

export default function VisitorsInBuildingModal ({ eventsData }) {
  const translate = useTranslate()

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant='text' color='primary' onClick={handleClickOpen}>
        {translate('messages.dashboard.showDetails')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='xl'
      >
        <DialogTitle id='alert-dialog-title'>{translate('messages.dashboard.visitorsInBuilding')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {eventsData && <VisitorsInBuildingTable eventsData={eventsData} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {translate('messages.dashboard.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
