import React, { createElement } from 'react'
import { Card, Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
import brown from '@material-ui/core/colors/brown'

import get from 'lodash/get'

import cartouche from '../../assets/cartouche.png'
import cartoucheDark from '../../assets/cartoucheDark.png'
import cartoucheSuccess from '../../assets/cartoucheSuccess.png'
import cartoucheInfo from '../../assets/cartoucheInfo.png'
import cartoucheBrown from '../../assets/cartoucheBrown.png'

const getImageByType = (type) => {
  const imagesByType = {
    success: cartoucheSuccess,
    error: cartouche,
    info: cartoucheInfo,
    brown: cartoucheBrown
  }

  return get(imagesByType, type, cartoucheDark)
}

const getColorByType = (type) => {
  const colorsByType = {
    success: green[500],
    error: red[500],
    info: blue[500],
    brown: brown[500]
  }

  return get(colorsByType, type, 'white')
}

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 52,
    height: '100%',
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    }
  },
  main: ({ type }) => ({
    overflow: 'inherit',
    padding: 16,
    background: `url(${getImageByType(type)}) no-repeat`,
    backgroundPosition: '-1px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .icon': {
      color: getColorByType(type)
    }
  }),
  title: {}
}))

const CardWithIcon = props => {
  const { icon, title, subtitle, children } = props
  const classes = useStyles(props)
  return (
    <Card className={classes.card}>
      <div className={classes.main}>
        <Box width='3em' className='icon'>
          {createElement(icon, { fontSize: 'large' })}
        </Box>
        <Box textAlign='right'>
          <Typography
            className={classes.title}
            color='textSecondary'
          >
            {title}
          </Typography>
          <Typography variant='h5' component='h2'>
            {subtitle == null ? ' ' : subtitle}
          </Typography>
        </Box>
      </div>
      {children && <Divider />}
      {children}
    </Card>
  )
}

export default CardWithIcon
