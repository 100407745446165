import { useMemo } from 'react'
import { useQueryWithStore } from 'react-admin'
import { useSelector } from 'react-redux'

import reduce from 'lodash/reduce'
import groupBy from 'lodash/groupBy'
import toNumber from 'lodash/toNumber'
import sortBy from 'lodash/sortBy'

import parseISO from 'date-fns/parseISO'
import startOfDay from 'date-fns/startOfDay'

import useDaysAgo from './useDaysAgo'
import moment from 'moment'

const dateIterator = ({ date }) => startOfDay(parseISO(date)).getTime()
const dateReducer = (acc, records, date) => [...acc, { date: toNumber(date), total: records.length }]

const useVisitorsTraffic = () => {
  const { propertyId } = useSelector(state => state.property)
  const days = useSelector(state => state.globalFilters.days)
  const deviceId = useSelector(state => state.globalFilters.deviceId)
  const aDaysAgo = useDaysAgo(days)
  const { loaded: enterEventsLoaded, data: enterEventsData } = useQueryWithStore({
    type: 'getList',
    resource: 'enterEvents',
    payload: {
      filter: {
        propertyId,
        'date[$gt]': aDaysAgo.toISOString(),
        deviceId
      },
      pagination: { page: 1, perPage: 1000 }
    }
  })
  const { loaded: deliveryEventsLoaded, data: deliveryEventsData } = useQueryWithStore({
    type: 'getList',
    resource: 'deliveryEvents',
    payload: {
      filter: {
        propertyId,
        'date[$gt]': aDaysAgo.toISOString(),
        deviceId
      },
      pagination: { page: 1, perPage: 1000 }
    }
  })
  const loaded = enterEventsLoaded && deliveryEventsLoaded

  const chartData = useMemo(
    () => {
      const data = [...deliveryEventsData || [], ...enterEventsData || []].sort((a, b) => {
        return moment(b.date).local() - moment(a.date).local()
      })
      return loaded ? sortBy(reduce(groupBy(data, dateIterator), dateReducer, []), 'date') : []
    },
    [loaded, deliveryEventsData, enterEventsData]
  )

  const chartDomain = [aDaysAgo.getTime(), new Date().getTime()]

  return {
    loaded,
    chartData,
    chartDomain
  }
}

export default useVisitorsTraffic
