import React from 'react'
import { useTranslate } from 'react-admin'

import PersonIcon from '@material-ui/icons/Person'
import CircularProgress from '@material-ui/core/CircularProgress'

import useEnterEvents from '../hooks/useEnterEvents'
import CardWithIcon from './CardWithIcon'

const AverageVisitors = () => {
  const translate = useTranslate()
  const { loaded, average } = useEnterEvents()
  const subtitle = loaded ? average || 0 : <CircularProgress size={24} />

  return (
    <CardWithIcon
      type='success'
      icon={PersonIcon}
      title={translate('messages.dashboard.averageVisitors')}
      subtitle={subtitle}
    />
  )
}

export default AverageVisitors
