import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslate } from 'react-admin'
import useSubscription from '../hooks/useSubscriptions'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import useStyles from '../theme'

const Subscriptions = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const {
    activeUsers,
    maxUsers
  } = useSubscription()
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          variant='h5'
          style={{
            marginBottom: 20
          }}
        >
          Subscriptions
        </Typography>
        <Typography
          variant='h6'
          style={{
            marginBottom: 5,
            fontWeight: 'bold',
            color: activeUsers > maxUsers ? 'red' : 'black'
          }}
        >
          {activeUsers}/{maxUsers} users
        </Typography>
        <Typography variant='small' component='small'>
          {translate('messages.settings.subscription')}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Subscriptions
