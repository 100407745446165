import React from 'react'
import { useTranslate } from 'react-admin'

import { Image } from 'cloudinary-react'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded'

import useTopDeliveryCompanies from '../hooks/useTopDeliveryCompanies'
import CardWithIcon from './CardWithIcon'

const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME

const useStyles = makeStyles(
  theme => ({
    image: {
      borderRadius: theme.spacing(4)
    }
  })
)

const TopDeliveryCompanies = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const { loaded, data } = useTopDeliveryCompanies()
  const hasData = data && data.length > 0

  const renderData = () => {
    if (hasData) {
      return data.map((record) => (
        <ListItem key={record.id}>
          <ListItemAvatar>
            <Image
              className={classes.image}
              cloudName={cloudName}
              publicId={record.photo?.publicId}
              height='40'
              width='40'
              fetchFormat='auto'
              crop='scale'
            />
          </ListItemAvatar>
          <ListItemText primary={record.name} />
          <ListItemText
            primary={`${record.deliveriesCount} ${
              record.deliveriesCount > 1 ? 'deliveries' : 'delivery'
            }`}
            style={{ textAlign: 'right' }}
          />
        </ListItem>
      ))
    }

    return (
      <ListItem
        alignItems='center'
      >
        <ListItemText primary='No delivery data' />
      </ListItem>
    )
  }

  return (
    <CardWithIcon
      type='brown'
      icon={LocalShippingRoundedIcon}
      title={translate('messages.dashboard.topFiveDeliveryCompanies')}
    >
      <List>
        {loaded
          ? (
              renderData()
            )
          : (
            <ListItem>
              <CircularProgress size={24} />
            </ListItem>
            )}
      </List>
    </CardWithIcon>
  )
}

export default TopDeliveryCompanies
