import { useQueryWithStore } from 'react-admin'
import { useSelector } from 'react-redux'

import { EMobileUsersFlatsStatus } from '../../enums/mobileUsersFlats'

const useActiveUsers = () => {
  const { propertyId } = useSelector(state => state.property)
  const { loaded, total } = useQueryWithStore({
    type: 'getList',
    resource: 'mobileUsersFlats',
    payload: {
      filter: {
        propertyId,
        status: EMobileUsersFlatsStatus.ACCEPTED
      },
      pagination: { page: 1, perPage: 1 }
    }
  })

  return {
    loaded,
    total
  }
}

export default useActiveUsers
