import React from 'react'
import { intervalToDuration } from 'date-fns'

const DurationField = (props) => {
  const durationSeconds = props.record[props.source] / 1000
  const duration = intervalToDuration({ start: 0, end: durationSeconds * 1000 || 0 })
  const formatted =
    `${duration.hours.toString().padStart(2, '0')}:${
    duration.minutes.toString().padStart(2, '0')}:${duration.seconds.toString().padStart(2, '0')}`
  return (
    <span>
      {durationSeconds ? formatted : '00:00:00'}
    </span>
  )
}

export default DurationField
