import React, { useCallback } from 'react'
import { useGetList, useGetMany, useQuery, useTranslate } from 'react-admin'
import { useSelector, useDispatch } from 'react-redux'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const DeviceFilter = () => {
  const dispatch = useDispatch()
  const { propertyId } = useSelector(state => state.property)
  const { data: devices } = useGetList('devices', undefined, undefined, {
    propertyId: propertyId
  })
  const deviceId = useSelector(state => state.globalFilters.deviceId)
  const handleChange = useCallback((event) => {
    dispatch({ type: 'GLOBAL_FILTERS_SET', filters: { deviceId: event.target.value } })
  }, [dispatch])

  return (
    <Select
      labelId='deviceId-filter-label'
      id='deviceId-filter'
      displayEmpty
      value={deviceId}
      onChange={handleChange}
    >
      <MenuItem value={undefined}>
        All devices
      </MenuItem>
      {Object.entries(devices)?.map((entry, index) => (
        <MenuItem key={index} value={entry[1]._id}>
          {entry[1].name}
        </MenuItem>
      ))}

    </Select>
  )
}

export default DeviceFilter
