import { useQueryWithStore } from 'react-admin'
import { useSelector } from 'react-redux'
import moment from 'moment'

const useVisitorsInBuilding = () => {
  const { propertyId } = useSelector(state => state.property)
  const { loaded: enterEventsLoaded, total: enterEventsTotal, data: enterEventsData } = useQueryWithStore({
    type: 'getList',
    resource: 'enterEvents',
    payload: {
      filter: {
        propertyId,
        left: false
      },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'date', order: 'DESC' }
    }
  })
  const { loaded: deliveryEventsLoaded, total: deliveryEventsTotal, data: deliveryEventsData } = useQueryWithStore({
    type: 'getList',
    resource: 'deliveryEvents',
    payload: {
      filter: {
        propertyId,
        left: false
      },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'date', order: 'DESC' }
    }
  })
  const { loaded: accessCardEventsLoaded, total: accessCardEventsTotal, data: accessCardEventsData } = useQueryWithStore({
    type: 'getList',
    resource: 'accessCardEvents',
    payload: {
      filter: {
        propertyId,
        left: false
      },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'date', order: 'DESC' }
    }
  })

  return {
    loaded: deliveryEventsLoaded && enterEventsLoaded && accessCardEventsLoaded,
    total: deliveryEventsTotal + enterEventsTotal,
    totalWithAccessCardEvents: deliveryEventsTotal + enterEventsTotal + accessCardEventsTotal,
    eventsData: [...deliveryEventsData || [], ...enterEventsData || []].sort((a, b) => {
      return moment(b.date).local() - moment(a.date).local()
    }),
    allEventsData: [...deliveryEventsData || [], ...enterEventsData || [], ...accessCardEventsData || []].sort((a, b) => {
      return moment(b.date).local() - moment(a.date).local()
    })
  }
}

export default useVisitorsInBuilding
