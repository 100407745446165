import { useQueryWithStore } from 'react-admin'
import { useSelector } from 'react-redux'

import useDaysAgo from './useDaysAgo'

const useLeaveEvents = () => {
  const { propertyId } = useSelector(state => state.property)
  const days = useSelector(state => state.globalFilters.days)
  const deviceId = useSelector(state => state.globalFilters.deviceId)
  const aMonthAgo = useDaysAgo(days)
  const { loaded: loadedTotal, total } = useQueryWithStore({
    type: 'getList',
    resource: 'leaveEvents',
    payload: {
      filter: {
        propertyId,
        'date[$gt]': aMonthAgo.toISOString(),
        deviceId
      },
      pagination: { page: 1, perPage: 1 }
    }
  })
  const { loaded: loadedAutoSignOut, total: totalAutoSignOut } = useQueryWithStore({
    type: 'getList',
    resource: 'leaveEvents',
    payload: {
      filter: {
        propertyId,
        leaveType: 'autoSignOut',
        'date[$gt]': aMonthAgo.toISOString(),
        deviceId
      },
      pagination: { page: 1, perPage: 1 }
    }
  })

  const loaded = loadedTotal && loadedAutoSignOut
  const shouldCalculatePercent = loaded && total != null && total !== 0
  const percent = shouldCalculatePercent ? `${Math.round(totalAutoSignOut / total * 100)}%` : '0%'

  return {
    loaded,
    percent
  }
}

export default useLeaveEvents
