import Typography from '@material-ui/core/Typography'
import React from 'react'
import useStyles from '../theme'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import useUserDetails from '../hooks/useUserDetails'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

const ChangeDetails = () => {
  const classes = useStyles()
  const {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    enableNotification,
    setEnableNotification,
    loading,
    changeDetails,
    userType
  } = useUserDetails()
  return (
    <Card className={classes.card}>
      <CardContent>
        <form noValidate>
          <Typography variant='h5' style={{ marginBottom: 10 }}>
            Change details
          </Typography>
          <Box display='block'>
            <TextField
              variant='filled'
              margin='normal'
              required
              id='name'
              label='Name'
              name='name'
              type='text'
              value={name}
              className={classes.input}
              onChange={e => setName(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box display='block'>
            <TextField
              variant='filled'
              margin='normal'
              required
              id='email'
              label='Email Address'
              name='email'
              type='email'
              value={email}
              className={classes.input}
              onChange={e => setEmail(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box display='block'>
            <TextField
              variant='filled'
              margin='normal'
              id='phone'
              label='Phone Number'
              name='phone'
              type='text'
              value={phone}
              className={classes.input}
              onChange={e => setPhone(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          {userType === 'propertyAdmins' && (<Box display='block'>
            <FormControlLabel
              control={<Switch />}
              id='enableNotification'
              name='enableNotification'
              label='Enable Notification'
              checked={enableNotification}
              onChange={(e, val) => setEnableNotification(val)}
            />
          </Box>)}
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={changeDetails}
            disabled={loading}
          >
            Change details
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

export default ChangeDetails
