import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector, useDispatch } from 'react-redux'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const DaysFilter = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const days = useSelector(state => state.globalFilters.days)
  const handleChange = useCallback((event) => {
    dispatch({ type: 'GLOBAL_FILTERS_SET', filters: { days: event.target.value } })
  }, [dispatch])

  return (
    <Select
      labelId='days-filter-label'
      id='days-filter'
      value={days}
      onChange={handleChange}
    >
      <MenuItem value={30}>
        {translate('messages.dashboard.lastDaysFilterOption', { days: 30 })}
      </MenuItem>
      <MenuItem value={14}>
        {translate('messages.dashboard.lastDaysFilterOption', { days: 14 })}
      </MenuItem>
      <MenuItem value={7}>
        {translate('messages.dashboard.lastDaysFilterOption', { days: 7 })}
      </MenuItem>
      <MenuItem value={1}>
        {translate('messages.dashboard.lastDayFilterOption', { days: 1 })}
      </MenuItem>
    </Select>
  )
}

export default DaysFilter
