// https://marmelab.com/react-admin/doc/3.8/List.html#bulk-action-buttons

import * as React from 'react'
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll
} from 'react-admin'

const BulkUpdateUnits = ({ selectedIds }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()

  const [updateMany, { loading }] = useUpdateMany(
    'flats',
    selectedIds,
    { isArchived: true },
    {
      onSuccess: () => {
        refresh()
        notify('Units updated')
        unselectAll('flats')
      },
      onFailure: () => notify('Error: units not updated', 'warning')
    }
  )

  return (
    <Button
      label='Archive units'
      disabled={loading}
      onClick={updateMany}
      variant='secondary'
    />
  )
}

export default BulkUpdateUnits
