import React from 'react'
import { useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'

import DaysFilter from '../containers/DaysFilter'
import DeviceFilter from '../containers/DeviceFilter'

import TotalVisitors from './cards/TotalVisitors'
import AverageVisitors from './cards/AverageVisitors'
import GuestsReported from './cards/GuestsReported'
import TotalDeliveries from './cards/TotalDeliveries'
import AverageDeliveries from './cards/AverageDeliveries'
import AutoSignOut from './cards/AutoSignOut'
import ActiveUsers from './cards/ActiveUsers'
import TopUnits from './cards/TopUnits'
import TopDeliveryCompanies from './cards/TopDeliveryCompanies'
import VisitorsTrafficChart from './charts/VisitorsTrafficChart'
import { Title } from 'react-admin'
import VisitorsInBuilding from './cards/VisitorsInBuilding'

const Dashboard = () => {
  const { propertyId } = useSelector(state => state.property)

  if (!propertyId) {
    return null // TODO przerobic na skeleton
  }

  return (
    <>
      <Title title='Dashboard' />
      <Toolbar>
        <Box flexGrow={1} />
        <Box mr={2}>
          <DeviceFilter />
        </Box>
        <DaysFilter />
      </Toolbar>
      <Grid container spacing={2} alignItems='stretch'>
        <Grid item xs={12} sm={6} md={4}>
          <TotalVisitors />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AverageVisitors />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <GuestsReported />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TotalDeliveries />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AverageDeliveries />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AutoSignOut />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12} sm={12}>
              <ActiveUsers />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TopUnits />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12} sm={12}>
              <VisitorsInBuilding />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TopDeliveryCompanies />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <VisitorsTrafficChart />
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
