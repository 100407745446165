import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  Filter,
  DateField,
  DateTimeInput,
  Create,
  SimpleForm,
  TextInput,
  required,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput, useListContext, CreateButton,
  Toolbar,
  SaveButton
} from 'react-admin'
import { useSelector } from 'react-redux'
import CutTextField from '../../elements/CutTextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

export const Filters = (props) => (
  <Filter {...props}>
    <DateTimeInput
      source='createdAt.$gt'
      label='From'
    />
    <DateTimeInput
      source='createdAt.$lt'
      label='To'
    />
  </Filter>
)

const CreateToolbar = (props) =>
  (
    <Toolbar {...props}>
      <SaveButton label='Send' />
    </Toolbar>
  )

export const PropertyNotificationCreate = (props) => {
  const { propertyId } = useSelector(state => state.property)
  return (
    <Create
      {...props}
      title='Create Notification'
    >
      <SimpleForm
        toolbar={<CreateToolbar />}
      >
        <TextInput
          source='title'
          label='Title'
          type='text'
          fullWidth
          validate={required()}
        />
        <TextInput
          source='text'
          label='Message'
          fullWidth
          multiline
          validate={required()}
        />
        <ReferenceInput
          source='propertyId'
          label='Property'
          reference='properties'
          validate={required()}
          defaultValue={propertyId}
          style={{
            display: 'none'
          }}
        >
          <SelectInput optionText='name' />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export const PropertyNotificationShow = (props) => (
  <Show
    {...props}
    title='Notification'
  >
    <SimpleShowLayout>
      <TextField
        source='title'
        label='Title'
        type='text'
      />
      <TextField
        source='text'
        label='Message'
        type='text'
      />
    </SimpleShowLayout>
  </Show>
)

const PropertyNotificationListEmpty = (props) => {
  const { basePath } = useListContext()
  return (
    <Box textAlign='center' m={4}>
      <Typography variant='h4' paragraph>
        No notifications yet
      </Typography>
      <Typography variant='body1'>
        Create new one
      </Typography>
      <CreateButton style={{ marginTop: 30 }} variant='contained' basePath={basePath} />
    </Box>
  )
}

export const PropertyNotificationList = (props) => {
  const { propertyId } = useSelector(state => state.property)

  return (
    <List
      {...props}
      exporter={false}
      filters={<Filters />}
      empty={<PropertyNotificationListEmpty />}
      bulkActionButtons={false}
      title='Notification Management'
      filter={{ propertyId: propertyId || undefined }}
    >
      <Datagrid>
        <TextField
          source='title'
          label='Title'
        />
        <CutTextField
          source='text'
          label='Message'
          length={100}
        />
        <DateField source='createdAt' showTime />
        <ShowButton />
      </Datagrid>
    </List>
  )
}
