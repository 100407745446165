import React from 'react'
import {
  Show,
  TextField,
  ImageField,
  TabbedForm,
  FormTab
} from 'react-admin'

export const MobileUsersShow = (props) => (
  <Show
    {...props}
    title='Host'
  >
    <TabbedForm
      toolbar={false}
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField
          source='email'
          label='Email'
        />
        <TextField
          source='phone'
          label='Phone'
        />
        <TextField
          source='name'
          label='Name'
        />
        <ImageField source='photo.publicUrl' label='User photo' />
        <ImageField source='emiratesData.frontImage.publicUrl' label='Front' />
        <ImageField source='emiratesData.backImage.publicUrl' label='Back' />
        <TextField
          source='emiratesData.mrzData.document_number'
          label='Document ID'
        />
        <TextField
          source='emiratesData.mrzData.given_names_readable'
          label='Name'
        />
        <TextField
          source='emiratesData.mrzData.surname'
          label='Surname'
        />
        <TextField
          source='emiratesData.mrzData.nationality'
          label='Nationality'
        />
        <TextField
          source='emiratesData.mrzData.sex'
          label='Gender'
        />
        <TextField
          source='emiratesData.mrzData.optionals'
          label='ID'
        />
        <TextField
          source='emiratesData.mrzData.expiration_date_readable'
          label='Expiration Date'
        />
        <TextField
          source='emiratesData.mrzData.dob_readable'
          label='DOB'
        />
        <TextField
          source='emiratesData.mrzData.issuing_country'
          label='Issuing Country'
        />
        <TextField
          source='emiratesData.mrzData.est_issuing_date_readable'
          label='Issuing Date'
        />
        <TextField
          source='accessCardId'
          label='Access Card ID'
        />
      </FormTab>
    </TabbedForm>
  </Show>
)
