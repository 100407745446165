import React from 'react'
import { Admin, Resource, usePermissions } from 'react-admin'
import { authClient, restClient } from 'ra-data-feathers'
import { Route } from 'react-router-dom'

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import DevicesIcon from '@material-ui/icons/Devices'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CardIcon from '@material-ui/icons/CreditCard'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import PersonIcon from '@material-ui/icons/Person'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'

import feathersClient from './client/feathersClient'
import i18nProvider from './i18nProvider'
import { FlatCreate, FlatEdit, FlatList } from './models/flats'
import { PropertyList } from './models/properties'
import propertyReducer from './reducers/propertyReducer'
import globalFiltersReducer from './reducers/globalFiltersReducer'
import Layout from './containers/Layout'
import {
  PropertyNotificationCreate,
  PropertyNotificationList,
  PropertyNotificationShow
} from './models/propertyNotifications'
import { DeviceList } from './models/devices'
import { LogView, LogShow } from './models/logs'
import {
  MobileUsersFlatsList,
  MobileUsersFlatsShow,
  MobileUsersFlatsCreate
} from './models/userManagement'
import {
  MobileUsersShow
} from './models/mobileUsers'
import {
  AccessCardEventsView
} from './models/accessCardEvents'
import LoginPage from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import { theme } from './theme'
import { Dashboard } from './dashboard'
import {
  PropertyManagersPropertyCreate,
  PropertyManagersPropertyList,
  PropertyManagersPropertyShow
} from './models/propertyManagersProperties'
import Settings from './pages/Settings'
import Menu from './elements/Menu'
import { AccessCardEdit, AccessCardsList } from './models/accessCards'
import VerifyToken from './pages/VerifyToken'

const restClientOptions = {
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
}

export const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { // Options included in calls to Feathers client.authenticate
    strategy: 'managementDashboard' // The authentication strategy Feathers should use
  },
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'email', // Our example database might use 'username' rather than 'email'
  redirectTo: '/login', // Our example login form might be at '/login', redirect here if AUTH_CHECK fails
  permissionsKey: 'accountType', // The key used to store the permissions in the LocalStorage store
  permissionsField: 'role'
}

const customRoutes = [
  <Route key='forgotPassword' exact path='/forgotPassword' component={ForgotPassword} noLayout />,
  <Route key='verifyToken' exact path='/verifyToken' component={VerifyToken} noLayout />,
  <Route key='resetPassword' exact path='/resetPassword' component={ResetPassword} noLayout />,
  <Route key='settings' exact path='/settings' component={Settings} />
]

const RestrictedResource = (props) => {
  const { permissions } = usePermissions()
  if (permissions === 'propertyAdmins') {
    return <Resource {...props} />
  } else {
    return null
  }
}

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Admin
        menu={Menu}
        theme={theme}
        customReducers={{
          property: propertyReducer,
          globalFilters: globalFiltersReducer
        }}
        dataProvider={restClient(feathersClient, restClientOptions)}
        authProvider={authClient(feathersClient, authClientOptions)}
        dashboard={Dashboard}
        locale='en'
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={LoginPage}
        customRoutes={customRoutes}
      >
        <Resource
          name='flats'
          options={{ label: 'Units' }}
          icon={MeetingRoomIcon}
          list={FlatList}
          create={FlatCreate}
          edit={FlatEdit}
        />
        <Resource
          name='properties'
          list={PropertyList}
          icon={LocationCityIcon}
        />
        <Resource
          name='propertyNotifications'
          options={{ label: 'Notification Management' }}
          icon={NotificationsActiveIcon}
          list={PropertyNotificationList}
          create={PropertyNotificationCreate}
          show={PropertyNotificationShow}
        />
        <Resource
          name='devices'
          list={DeviceList}
          icon={DevicesIcon}
        />
        <Resource
          name='events'
          options={{ label: 'Logs' }}
          list={LogView}
          show={LogShow}
          icon={AssignmentIcon}
        />
        <Resource
          name='accessCardEvents'
          options={{ label: 'Access Card Logs' }}
          list={AccessCardEventsView}
          icon={DynamicFeedIcon}
        />
        <Resource
          name='mobileUsersFlats'
          options={{ label: 'User management' }}
          list={MobileUsersFlatsList}
          show={MobileUsersFlatsShow}
          create={MobileUsersFlatsCreate}
          icon={PersonIcon}
        />
        <RestrictedResource
          name='accessCards'
          options={{ label: 'Access Cards' }}
          list={AccessCardsList}
          edit={AccessCardEdit}
          icon={CardIcon}
        />
        <Resource
          name='mobileUsers'
          show={MobileUsersShow}
        />
        <Resource
          name='propertyManagers'
        />
        <Resource
          name='accesses'
        />
        <Resource
          name='logs'
        />
        <Resource
          name='propertyManagersProperties'
          options={{ label: 'Managers' }}
          icon={BusinessCenterIcon}
          list={PropertyManagersPropertyList}
          show={PropertyManagersPropertyShow}
          create={PropertyManagersPropertyCreate}
        />
      </Admin>
    </MuiPickersUtilsProvider>
  )
}

export default App
