import { TextField } from 'react-admin'
import React from 'react'

const CutTextField = (props) => {
  const value = props.record[props.source]
  props.record[props.source] = value?.length > 20 ? value.toString().substring(0, props.length) + '...' : value

  return (
    <TextField
      {...props}
    />
  )
}

export default CutTextField
