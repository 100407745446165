import React from 'react'
import { useTranslate } from 'react-admin'

import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CircularProgress from '@material-ui/core/CircularProgress'

import useActiveUsers from '../hooks/useActiveUsers'
import CardWithIcon from './CardWithIcon'

const ActiveUsers = () => {
  const translate = useTranslate()
  const { loaded, total } = useActiveUsers()
  const subtitle = loaded ? total || 0 : <CircularProgress size={24} />

  return (
    <CardWithIcon
      type='info'
      icon={PersonAddIcon}
      title={translate('messages.dashboard.activeUsers')}
      subtitle={subtitle}
    />
  )
}

export default ActiveUsers
