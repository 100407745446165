import Typography from '@material-ui/core/Typography'
import {
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  Edit,
  SimpleForm,
  SaveButton, required, useQuery, email
} from 'react-admin'
import { useSelector } from 'react-redux'
import React from 'react'
import useStyles from '../theme'
import { makeStyles } from '@material-ui/core'
import useUser from '../../../hooks/useUser'
import Box from '@material-ui/core/Box'

const FormToolbar = (props) => {
  const classes = useStyles()

  return (
    <Box p={2} mb={2}>
      <SaveButton
        type='submit'
        variant='contained'
        color='primary'
        size='medium'
        className={classes.submit}
        label='Save changes'
        {...props}
        icon={<></>}
        startIcon={<></>}
      />
    </Box>
  )
}

export const DailyEventsReportsReceivers = () => {
  const classes = useStyles()
  const { propertyId } = useSelector(state => state.property)
  const validateEmail = [required(), email()]
  const {
    id
  } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })
  const useIteratorStyle = makeStyles(() => ({
    root: {
      margin: 0
    },
    form: {
      display: 'flex',
      flexDirection: 'row',
      gap: 40
    }
  }))
  const iteratorClasses = useIteratorStyle()
  const transform = data => ({
    dailyEventsReportsReceivers: data.dailyEventsReportsReceivers
  })

  if (userData?.type !== 'propertyAdmins') return null

  return (
    <Edit
      transform={transform}
      successMessage='Daily Logs Reports receivers are updated'
      undoable={false}
      className={classes.card}
      resource='properties'
      basePath='properties'
      title={' '}
      id={propertyId}
    >
      <SimpleForm redirect={false} toolbar={<FormToolbar />}>
        <Typography
          variant='h5'
          component='h2'
          style={{
            marginBottom: 30,
            width: '100%'
          }}
        >
          Daily Logs Reports Receivers
        </Typography>
        <ArrayInput
          source='dailyEventsReportsReceivers'
          label={false}
        >
          <SimpleFormIterator classes={iteratorClasses} inline margin='none'>
            <TextInput type='email' validate={validateEmail} source='email' label='Email Address' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default DailyEventsReportsReceivers
