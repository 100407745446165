import useVisitorsInBuilding from '../useVisitorsInBuilding'
import * as XLSX from 'xlsx-js-style'
import { useSelector } from 'react-redux'
import { useDataProvider, useGetOne } from 'react-admin'
import createWorksheetData from './createWorksheetData'
import moment from 'moment'

const MAX_QUERY_ARRAY_LENGTH = 200

const useVisitorsInBuildingReport = () => {
  const { propertyId } = useSelector(state => state.property)
  const dataProvider = useDataProvider()
  const { data: propertyData } = useGetOne('properties', propertyId, { enabled: !!propertyId })
  const { allEventsData, loaded, totalWithAccessCardEvents } = useVisitorsInBuilding()

  const downloadAsXLSXFile = (data) => {
    const name = ('active_visitors_' + propertyData.name).replace(/ /g, '_').toLowerCase()

    /* Create worksheet */
    const worksheetData = createWorksheetData(propertyData.name, data)

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

    if (!worksheet['!merges']) {
      worksheet['!merges'] = []
    }
    worksheet['!merges'].push(XLSX.utils.decode_range('B1:F3'))
    worksheet['!merges'].push(XLSX.utils.decode_range('A6:F7'))

    worksheet['!cols'] = [
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 10 }
    ]

    const workbook = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(workbook, worksheet, propertyData.name.substring(0, 30))

    XLSX.writeFile(workbook, `${name}.xlsx`)
  }

  const generateReport = () => {
    if (allEventsData && loaded && totalWithAccessCardEvents > 0) {
      const mobileUsersIds = allEventsData.map((event) => event.hostId)
        .filter((item, index, array) => array.indexOf(item) === index)
      const flatsIds = allEventsData.map((event) => event.flatId)
        .filter((item, index, array) => array.indexOf(item) === index)
      let mobileUsers = []
      let flats = []
      const numbersOfRequests = Math.ceil(allEventsData.length / MAX_QUERY_ARRAY_LENGTH)
      const promises = []
      for (let i = 0; i < numbersOfRequests; i++) {
        promises.push(new Promise((resolve) => {
          dataProvider.getMany(
            'mobileUsers',
            {
              ids: mobileUsersIds.slice(i * MAX_QUERY_ARRAY_LENGTH, (i + 1) * MAX_QUERY_ARRAY_LENGTH)
            }
          )
            .then(({ data }) => {
              mobileUsers = [...mobileUsers, ...data]
              resolve()
            })
        }))
        promises.push(new Promise((resolve) => {
          dataProvider.getMany(
            'flats',
            {
              ids: flatsIds.slice(i * MAX_QUERY_ARRAY_LENGTH, (i + 1) * MAX_QUERY_ARRAY_LENGTH)
            }
          )
            .then(({ data }) => {
              flats = [...flats, ...data]
              resolve()
            })
        }))
      }

      Promise.all(promises).then(() => {
        const preparedData = allEventsData.map((event) => ({
          date: moment(event.createdAt).format('MMMM Do YYYY'),
          time: moment(event.createdAt).format('HH:mm:ss'),
          name: event.guestName || event.delivererName || mobileUsers.find(mobileUser => mobileUser._id === event.hostId)?.name || '',
          unitNumber: flats.find(flat => flat._id === event.flatId)?.flatNumber ?? '',
          phone: event.guestMobileNumber || event.delivererMobileNumber || mobileUsers.find(mobileUser => mobileUser._id === event.hostId)?.phone || '',
          isAccessCard: event.accessCardId ? 'Yes' : 'No'
        }))

        downloadAsXLSXFile(preparedData)
      })
    }
  }

  return {
    generateReport,
    canGenerateReport: loaded && totalWithAccessCardEvents > 0
  }
}

export default useVisitorsInBuildingReport
