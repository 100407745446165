import React from 'react'

const TimeField = (props) => {
  const date = new Date(props.record[props.source])
  return (
    <span>
      {date.getHours().toString().padStart(2, '0')}:
      {date.getMinutes().toString().padStart(2, '0')}
    </span>
  )
}

export default TimeField
