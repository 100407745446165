import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import useStyles from '../theme'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const TabButton = (props) => {
  const { profile, index, deleteProfile, activeProfile, setActiveProfile, setProfileName } = props
  const [showRename, setShowRename] = useState(false)
  const [rename, setRename] = useState(profile.name)

  return (
    <ClickAwayListener onClickAway={() => {
      setProfileName(index, rename)
      setShowRename(false)
    }}
    >
      <Button
        key={profile.name}
        disableElevation
        disableFocusRipple
        variant='contained'
        onClick={() => setActiveProfile(index)}
        {...(activeProfile === index) && { color: 'primary' }}
      >
        <Box marginRight={2}>{!showRename
          ? profile.name
          : (
            <TextField
              variant='standard'
              value={rename}
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={event => setRename(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  setProfileName(index, rename)
                  setShowRename(false)
                }
              }}
            />
            )}
        </Box>
        {!showRename && <IconButton
          style={{ zIndex: 10 }} onClick={(event) => {
            event.stopPropagation()
            setShowRename(true)
          }} size='small'
                        >
          <EditIcon
            color='light'
            {...(activeProfile !== index) && { style: { color: '#8f8f8f' } }}
          />
        </IconButton>}
        <IconButton
          style={{ zIndex: 10, marginLeft: 0, marginRight: -8 }} onClick={(event) => {
            event.stopPropagation()
            if (activeProfile === index) {
              setActiveProfile(-1)
            }
            deleteProfile(index)
          }} size='small'
        >
          <DeleteIcon color='error' />
        </IconButton>
      </Button>
    </ClickAwayListener>
  )
}

export const BusinessHours = (props) => {
  const classes = useStyles()
  const { businessHours, setWeekdayBusinessHours, createNewProfile, setProfileName, deleteProfile } = props
  const [activeProfile, setActiveProfile] = useState(-1)

  return (
    <Box>
      <Box display='flex' mt={2} style={{ gap: 16 }}>
        <Button
          disableElevation
          variant='contained'
          onClick={() => setActiveProfile(-1)}
          {...(activeProfile === -1) && { color: 'primary' }}
          style={{
            height: 42
          }}
        >Default
        </Button>
        {businessHours?.profiles?.map((profile, index) => (
          <TabButton
            key={profile.name}
            profile={profile}
            index={index}
            activeProfile={activeProfile}
            setActiveProfile={setActiveProfile}
            setProfileName={setProfileName}
            deleteProfile={deleteProfile}
          />
        ))}
        <Button onClick={() => createNewProfile()} disableTouchRipple color='inherit' variant='text'>
          <AddIcon color='inherit' style={{ marginRight: 8 }} />
          Add new profile
        </Button>
      </Box>
      {Object.keys(businessHours).filter(name => name !== 'profiles').map((weekday) =>
        <Box key={weekday} display='flex' alignItems='center'>
          {/* <FormControlLabel */}
          {/*  style={{ */}
          {/*    width: '100%', */}
          {/*    maxWidth: 200 */}
          {/*  }} */}
          {/*  control={<Switch />} */}
          {/*  id={`businessHours.${weekday}.isActive`} */}
          {/*  name={`businessHours.${weekday}.isActive`} */}
          {/*  label={weekday.toLowerCase().at(0).toUpperCase() + weekday.toLowerCase().substring(1, weekday.length)} */}
          {/*  checked={businessHours[weekday].isActive} */}
          {/*  onChange={(e, val) => setWeekdayBusinessHours(weekday, 'isActive', val)} */}
          {/* /> */}
          <Typography
            style={{
              width: '100%',
              maxWidth: 200
            }}
          >{weekday.toLowerCase().at(0).toUpperCase() + weekday.toLowerCase().substring(1, weekday.length)}
          </Typography>
          <TextField
            variant='filled'
            margin='normal'
            label='Time In'
            id={activeProfile === -1 ? `businessHours.${weekday}.timeIn` : `businessHours.profiles.${activeProfile}.${weekday}.timeIn`}
            name={activeProfile === -1 ? `businessHours.${weekday}.timeIn` : `businessHours.profiles.${activeProfile}.${weekday}.timeIn`}
            value={activeProfile === -1 ? businessHours[weekday].timeIn : businessHours.profiles[activeProfile][weekday].timeIn}
            onChange={e => setWeekdayBusinessHours(weekday, 'timeIn', e.target.value, activeProfile)}
            // disabled={!businessHours[weekday].isActive}
            type='time'
            className={classes.input}
            InputLabelProps={{ shrink: true }}
            style={{ marginRight: 16 }}
          />
          <TextField
            variant='filled'
            margin='normal'
            label='Time Out'
            type='time'
            id={activeProfile === -1 ? `businessHours.${weekday}.timeOut` : `businessHours.profiles.${activeProfile}.${weekday}.timeOut`}
            name={activeProfile === -1 ? `businessHours.${weekday}.timeOut` : `businessHours.profiles.${activeProfile}.${weekday}.timeOut`}
            value={activeProfile === -1 ? businessHours[weekday].timeOut : businessHours.profiles[activeProfile][weekday].timeOut}
            onChange={e => setWeekdayBusinessHours(weekday, 'timeOut', e.target.value, activeProfile)}
            // value={!activeProfile ? businessHours[weekday].timeOut : businessHours.profiles[activeProfile][weekday].timeOut}
            // disabled={!businessHours[weekday].isActive}
            className={classes.input}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      )}
    </Box>
  )
}

export default BusinessHours
