import { useState } from 'react'
import { useMutation, useNotify, useQuery } from 'react-admin'
import useUser from '../../../hooks/useUser'

const useUserDetails = () => {
  const notify = useNotify()
  const {
    id
  } = useUser()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [enableNotification, setEnableNotification] = useState(false)
  const { data } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  }, {
    onSuccess: ({ data }) => {
      setEmail(data?.email)
      setPhone(data?.phone)
      setName(data?.name)
      setEnableNotification(data?.enableNotification)
    }
  })
  const [save, { loading }] = useMutation({
    type: 'update',
    resource: data?.type,
    payload: {
      id: id,
      data: {
        name,
        email,
        phone,
        enableNotification,
      }
    }
  })

  const changeDetails = async (event) => {
    event.preventDefault()
    if (!name) {
      notify('messages.settings.nameRequired', 'error')
      return false
    }
    if (!email) {
      notify('messages.settings.emailRequired', 'error')
      return false
    }
    save()
    notify('messages.settings.userDetailsSave')
  }
  return {
    changeDetails,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    enableNotification,
    setEnableNotification,
    loading,
    userType: data?.type
  }
}

export default useUserDetails
