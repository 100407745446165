import { useMemo } from 'react'
import { useQueryWithStore } from 'react-admin'
import { useSelector } from 'react-redux'

import map from 'lodash/map'
import find from 'lodash/find'
import assign from 'lodash/assign'
import flow from 'lodash/flow'
import fpGroupBy from 'lodash/fp/groupBy'
import fpOrderBy from 'lodash/fp/orderBy'
import fpSlice from 'lodash/fp/slice'
import fpMap from 'lodash/fp/map'
import fpToPairs from 'lodash/fp/toPairs'

import useDaysAgo from './useDaysAgo'

const deliveryCompanyMapper = ([_id, records]) => ({ _id, deliveriesCount: records.length })
const deliveryCompanyAggregator = flow([
  fpGroupBy('companyId'),
  fpToPairs,
  fpMap(deliveryCompanyMapper),
  fpOrderBy('deliveriesCount', 'desc'),
  fpSlice(0, 5)
])

const useTopDeliveryCompanies = () => {
  const { propertyId } = useSelector(state => state.property)
  const days = useSelector(state => state.globalFilters.days)
  const deviceId = useSelector(state => state.globalFilters.deviceId)
  const aDaysAgo = useDaysAgo(days)
  const { loaded: deliveryEventsLoaded, data: deliveryEventsData } = useQueryWithStore({
    type: 'getList',
    resource: 'deliveryEvents',
    payload: {
      filter: {
        propertyId,
        'date[$gt]': aDaysAgo.toISOString(),
        deviceId
      },
      pagination: { page: 1, perPage: 10000 }
    }
  })

  const deliveryCompanyStats = useMemo(
    () => deliveryEventsLoaded
      ? deliveryCompanyAggregator(deliveryEventsData)
      : [],
    [deliveryEventsLoaded, deliveryEventsData]
  )

  const { loaded: deliveryCompaniesLoaded, data: deliveryCompaniesData } = useQueryWithStore({
    type: 'getList',
    resource: 'deliveryCompanies',
    payload: {
      filter: {
        _id: map(deliveryCompanyStats, '_id')
      },
      pagination: { page: 1, perPage: 5 }
    }
  })

  const loaded = deliveryEventsLoaded && deliveryCompaniesLoaded

  const mergedDeliveryCompanyStats = useMemo(
    () => loaded
      ? map(
          deliveryCompanyStats,
          (deliveryCompany) =>
            assign({}, deliveryCompany, find(deliveryCompaniesData, ['_id', deliveryCompany._id]))
        )
      : [],
    [loaded, deliveryCompanyStats, deliveryCompaniesData]
  )

  return {
    loaded,
    data: mergedDeliveryCompanyStats
  }
}

export default useTopDeliveryCompanies
