import { useMemo } from 'react'
import { useQueryWithStore } from 'react-admin'
import { useSelector } from 'react-redux'

import map from 'lodash/map'
import find from 'lodash/find'
import assign from 'lodash/assign'
import flow from 'lodash/flow'
import fpGroupBy from 'lodash/fp/groupBy'
import fpOrderBy from 'lodash/fp/orderBy'
import fpSlice from 'lodash/fp/slice'
import fpMap from 'lodash/fp/map'
import fpToPairs from 'lodash/fp/toPairs'

import useDaysAgo from './useDaysAgo'

const unitMapper = ([_id, records]) => ({ _id, visitorsCount: records.length })
const unitAggregator = flow([
  fpGroupBy('flatId'),
  fpToPairs,
  fpMap(unitMapper),
  fpOrderBy('visitorsCount', 'desc'),
  fpSlice(0, 5)
])

const useTopUnits = () => {
  const { propertyId } = useSelector(state => state.property)
  const days = useSelector(state => state.globalFilters.days)
  const deviceId = useSelector(state => state.globalFilters.deviceId)
  const aDaysAgo = useDaysAgo(days)
  const { loaded: enterEventsLoaded, data: enterEventsData } = useQueryWithStore({
    type: 'getList',
    resource: 'enterEvents',
    payload: {
      filter: {
        propertyId,
        'date[$gt]': aDaysAgo.toISOString(),
        deviceId
      },
      pagination: { page: 1, perPage: 1000 }
    }
  })

  const unitStats = useMemo(
    () => enterEventsLoaded
      ? unitAggregator(enterEventsData)
      : [],
    [enterEventsLoaded, enterEventsData]
  )

  const { loaded: loadedUnits, data: unitsData } = useQueryWithStore({
    type: 'getList',
    resource: 'flats',
    payload: {
      filter: {
        _id: map(unitStats, '_id')
      },
      pagination: { page: 1, perPage: 5 }
    }
  })

  const loaded = enterEventsLoaded && loadedUnits

  const mergedUnitStats = useMemo(
    () => loaded
      ? map(unitStats, (unit) => assign({}, unit, find(unitsData, ['_id', unit._id])))
      : [],
    [loaded, unitStats, unitsData]
  )

  return {
    loaded,
    data: mergedUnitStats
  }
}

export default useTopUnits
