import React from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { getResources, MenuItemLink } from 'react-admin'
import { withRouter } from 'react-router-dom'
import DefaultIcon from '@material-ui/icons/ViewList'
import SettingsIcon from '@material-ui/icons/Settings'
import DashboardIcon from '@material-ui/icons/Dashboard'
import Button from '@material-ui/core/Button'
import useVisitorsInBuildingReport from '../hooks/useVisitorsInBuildingReport'

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const { generateReport, canGenerateReport } = useVisitorsInBuildingReport()
  return (
    <div style={{
      marginTop: 30
    }}
    >
      <MenuItemLink
        to='/'
        primaryText='Dashboard'
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {resources
        .filter(resource => resource.hasList)
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={{
              pathname: `/${resource.name}`,
              search: 'displayedFilters='
            }}
            primaryText={resource.options.label || resource.name}
            onClick={onMenuClick}
            leftIcon={
              resource.icon ? <resource.icon /> : <DefaultIcon />
            }
            style={{
              textTransform: 'capitalize'
            }}
            sidebarIsOpen={open}
          />
        ))}
      <MenuItemLink
        to='/settings'
        primaryText='Settings'
        leftIcon={<SettingsIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {open && <Button
        onClick={generateReport}
        disabled={!canGenerateReport}
        style={{ margin: 16, paddingLeft: 13, paddingRight: 13, textAlign: 'center' }}
        color='primary'
        variant='contained'
      >Download On-site List</Button>}
      {isXSmall && logout}
    </div>
  )
}

export default withRouter(Menu)
