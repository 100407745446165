import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts'
import { useTranslate } from 'react-admin'

import useVisitorsTraffic from '../hooks/useVisitorsTraffic'

const dateFormatter = (date) =>
  new Date(date).toLocaleDateString()

const VisitorsTrafficChart = () => {
  const translate = useTranslate()
  const { loaded, chartData, chartDomain } = useVisitorsTraffic()

  return (
    <Card>
      <CardHeader title={translate('messages.dashboard.visitorsTraffic')} />
      <CardContent>
        {
          loaded
            ? (
              <ResponsiveContainer width='98%' height={300}>
                <LineChart data={chartData}>
                  <XAxis
                    dataKey='date'
                    name='Date'
                    type='number'
                    scale='time'
                    domain={chartDomain}
                    tickFormatter={dateFormatter}
                  />
                  <YAxis dataKey='total' name='Visitors' />
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip
                    cursor={{ strokeDasharray: '3 3' }}
                    labelFormatter={dateFormatter}
                  />
                  <Line type='monotone' dataKey='total' stroke='#feaa14' />
                </LineChart>
              </ResponsiveContainer>
              )
            : <CircularProgress size={24} />
        }
      </CardContent>
    </Card>
  )
}

export default VisitorsTrafficChart
